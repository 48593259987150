import { PrintConfigurationComponent } from './../print-layout/print-configuration/print-configuration.component';
import { InstrumentsService } from './../instruments.service';
import { Instrument } from './../instrument.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog } from '@angular/material';
import { InstrumentIssuingComponent } from '../instrument-issuing/instrument-issuing.component';
import { InstrumentDetailComponent } from '../instrument-detail/instrument-detail.component';

@Component({
  selector: 'app-instrument-table',
  templateUrl: './instrument-table.component.html',
  styleUrls: ['./instrument-table.component.css']
})
export class InstrumentTableComponent implements OnInit {

  instruments: any[] = [];
  filteredInstruments: any[] = [];

  listData: MatTableDataSource<any>;
  isLoading = true;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  searchKey = '';
  clickedClear = 'clicked';
  displayedColumns: string[] = ['Izdaj', 'Fotografija', 'Naziv', 'invetarskiBroj', 'serijskiBroj', 'Tip', 'Proizvodjac',
    'GodinaProizvodnje',];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  i = 0;
  selectedElements: string[] = [];
  oneMoreTimeAdded: boolean = false;


  constructor(private instrumentService: InstrumentsService,
    private dialog: MatDialog, ) { }

  ngOnInit() {



    this.instrumentService.getInstrumentsBySelectedSchool(this.instrumentService.currentSchooKey)
      .subscribe(
        list => {
          this.filteredInstruments = list.map(item => {
            return {
              $key: item.key,
              ...item.payload.val() as any
            }

          })
          this.instruments = this.filteredInstruments;
          this.filteredInstruments = this.instruments;
          this.listData = new MatTableDataSource(this.instruments);
          this.listData.sort = this.matSort;
          this.listData.paginator = this.paginator;
          this.isLoading = false;

        });
  }

  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  addColumn() {

    if (!this.oneMoreTimeAdded) {
      this.displayedColumns.push('NacinNabavke');
      this.displayedColumns.push('Cena');
      this.displayedColumns.push('Status');
      this.displayedColumns.push('Prostorija');
      this.displayedColumns.push('Komentar');
      this.oneMoreTimeAdded = true;
    }
    this.i = this.columnsToDisplay.length;
    if (this.i < this.displayedColumns.length)
      this.columnsToDisplay.push(this.displayedColumns[this.i++]);
  }
  removeColumn() {
    if (this.columnsToDisplay.length > 7)
      if (this.columnsToDisplay.length) {
        this.columnsToDisplay.pop();
      }
  }

  onOpenPrintConfiguration() {
    this.dialog.open(PrintConfigurationComponent, {
      disableClose: false,
      autoFocus: true,
      width: '300px',
      height: '',

      data: {
        displayedColumns: this.instrumentService.columnsForPrinting = this.columnsToDisplay.filter(item => item !== 'Fotografija' && item !== 'Izdaj'),
        selectedRows: this.instrumentService.dataFromTableForPrinting = this.listData.filteredData
      }
    });
  }

  onOpenInstrumentIssuing(key, schoolKey) {

    this.dialog.open(InstrumentIssuingComponent, {
      disableClose: false,
      autoFocus: true,
      width: '1000px',
      height: '700px',

      data: {
        instrumentKey: schoolKey + '/' + key
      }
    });
  }

  onOpenInstrumentDetail(element, event) {

    if (event.srcElement.innerText !== 'shopping_cart') {

      this.dialog.open(InstrumentDetailComponent, {
        disableClose: false,
        autoFocus: true,
        height: '800px',
        width: '1200px',
        data: {
          instrumentKey: element.SchoolKey + '/' + element.$key,
          rowClicked: true
        }
      });

    }

  }

}
