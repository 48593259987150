import { Component, OnInit, ViewChild } from '@angular/core';
import { InstrumentDetailComponent } from '../instrument-detail/instrument-detail.component';
import { IssuingDetailComponent } from '../instrument-issuing/issuing-detail/issuing-detail.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SharedClass } from 'src/app/shared/shared-functions';
import { InstrumentsService } from '../instruments.service';
import { PrintreportConfigurationComponent } from './printreport-configuration/printreport-configuration.component';

interface Statistics {
  totalIssuedInstruments: number;
  totalBackInstruments: number;
}

@Component({
  selector: 'app-instrument-reports',
  templateUrl: './instrument-reports.component.html',
  styleUrls: ['./instrument-reports.component.css']
})



export class InstrumentReportsComponent implements OnInit {


  user: firebase.User;
  userObject: User;
  userIssues: any[] = [];
  allIssues: any[] = [];
  mappedIssues: { index: number, key: string }[] = [];
  filteredIssuedInstrument: any[] = [];
  @ViewChild('ownIssueSearchInput', { static: false }) inputOwnIssue: any;
  @ViewChild('SchoolIssueSearchInput', { static: false }) inputSchoolIssue: any;

  filteredDataForUser: any[] = []
  filteredDataForSchool: any[] = []


  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = '';
  clickedClear = 'clicked';
  displayedColumns: string[] = ['Korisnik', 'Email', 'Naziv', 'Date', 'Time', 'Status', 'Options'];
  statistics: Statistics = { totalBackInstruments: 0, totalIssuedInstruments: 0 };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  constructor(private auth: AuthService,
    private router: Router, private dialog: MatDialog,
    private sharedClass: SharedClass,
    private instrumentService: InstrumentsService
  ) { }

  ngOnInit() {
    this.auth.getUserState()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.auth.getObjectOfLoggedUser(user.uid).subscribe(item => {
            this.userObject = item;
            this.loadAllIssues();
            this.filteredIssuedInstrument = [];

          });
          this.dialog.closeAll();
          this.sharedClass.oneImagedownloadUrl = [];
          this.loadAllIssues();
        }
      })

  }

  loadAllIssues() {

    if (this.userObject)
      return this.instrumentService.getAllInstrumentIssuing(this.userObject.School.key).subscribe(
        list => {
          this.allIssues = list.map(item => {
            return {
              $key: item.key,
              ...item.payload.val()
            }
          })
          this.filterIssuesForLoggedSchool()
        }
      );
  }

  filterIssuesForLoggedSchool() {

    let temp = [];

    this.allIssues.forEach(element => {

      temp.push({

        instrumentKey: element.Instrument.key,
        Naziv: element.Instrument.Naziv,
        Cena: element.Instrument.Cena,
        User: element.User.imePrezime,
        IssuingDate: element.Instrument.IssuingDate,
        IssuingTime: element.Instrument.IssuingTime,
        Email: element.User.email,
        IssuingKey: element.key,
        Status: element.Instrument.Status,

      })

    });


    this.listData = new MatTableDataSource(temp);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;

    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status !== 'Zauzet').length;
    this.filteredClick = false;
  }


  searchSchoolIssues() {

    let searchInput = this.inputSchoolIssue.nativeElement.value.toString().toLowerCase();

    this.filteredDataForSchool = this.filteredIssuedInstrument.filter((item: any) => {
      return item.Instrument.Naziv.toLowerCase().includes(searchInput)
        || item.Instrument.Cena.toLowerCase().includes(searchInput)
        || item.Instrument.GodinaProizvodnje.toLowerCase().includes(searchInput)
        || item.Instrument.IssuingTime.toLowerCase().includes(searchInput)
        || item.Instrument.School.Naziv.toLowerCase().includes(searchInput)

    });

  }

  filteredClick = false;
  filterDataOnTakenInstrument() {

    this.listData.filter = 'Zauzet'
    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status === 'Zauzet').length;
  }

  filterDataOnBackInstrument() {

    this.listData.filter = ''
    this.listData.filteredData.filter(item => item.Status !== 'Zauzet');
    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status !== 'Zauzet').length;
    this.filteredClick = true;

  }


  onOpenInstrumentDetail(key) {
    this.dialog.open(InstrumentDetailComponent, {
      disableClose: false,
      autoFocus: true,
      height: '800px',
      width: '1200px',
      data: {
        instrumentKey: key,
        rowClicked: true
      }
    });
  }

  onOpenIssuingDetail(issue, issuingKey) {

    let temp = this.allIssues.filter(item =>
      item.Instrument.key === issue.instrumentKey && item.User.email === issue.Email && item.Instrument.IssuingDate === issue.IssuingDate
      && item.Instrument.IssuingTime === issue.IssuingTime)


    this.dialog.open(IssuingDetailComponent, {
      disableClose: false,
      autoFocus: true,
      width: '1000px',
      height: '700px',
      data: {
        issue: temp[0],
        issuingKey: temp[0].Instrument.School.key + '/' + temp[0].$key
      }
    })
      .afterClosed().subscribe(() => this.filterIssuesForLoggedSchool());
  }

  onTakenInstrument() {
    this.applyFilter('Zauzet');
  }

  onBackenInstrument() {
    this.applyFilter('Razdužen');
  }

  applyFilter(searchKey?) {

    if (this.searchKey)
      this.listData.filter = this.searchKey.trim().toLowerCase();
    else
      this.listData.filter = searchKey

    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status !== 'Zauzet').length;
  }

  onOpenPrintConfiguration() {

    this.dialog.open(PrintreportConfigurationComponent
      , {
        disableClose: false,
        autoFocus: true,
        width: '',
        height: '',
        data: {
          dataForPrintingReport: this.instrumentService.dataForPrintingReport = this.listData.filteredData
        }
      }
    )

  }

}
