import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class SharedClass {
    ref: AngularFireStorageReference[] = [];
    task: AngularFireUploadTask[] = [];
    uploadState: Observable<string>;
    downloadURL: Observable<string>;
    submitted: boolean;
    imageFiles;
    downloadURLs: Observable<string>[] = [];
    currentFileName: '';
    fileCounter: number = 0;
    progressValue: number = 0;
    uploadedBoolean: boolean = true;
    downloadUrl = '';

    constructor(private _snackBar: MatSnackBar,
        private afStorage: AngularFireStorage,
        private authService: AuthService) { }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }


    uploadImages(event, category) {
        this.progressValue = 0;
        this.imageFiles = event.addedFiles;

        for (let i = 0; i < this.imageFiles.length; i++) {

            const id = Math.random().toString(36).substring(2) + i;
            this.ref[i] = this.afStorage.ref('/' + category + '/' + id);
            this.task[i] = this.ref[i].put(event.addedFiles[i]);
            this.task[i].snapshotChanges().pipe(

                finalize(() => {
                    this.ref[i].getDownloadURL().subscribe(url => {
                        this.currentFileName = this.imageFiles[i].name;
                        this.fileCounter++;

                        if (this.fileCounter <= this.imageFiles.length) {
                            let temp = 100 / this.imageFiles.length;
                            this.progressValue += temp;
                            if (this.fileCounter == this.imageFiles.length)
                                this.uploadedBoolean = true;
                        }
                        this.downloadURLs[i] = url;
                        //console.log(url)// <-- do what ever you want with the url..
                    });
                }))
                .subscribe();

        }
    }

    oneImageFiles;
    refOneImage: AngularFireStorageReference;
    taskOneImage: AngularFireUploadTask;
    oneImagedownloadUrl: string[] = [];

    uploadImage(event, category) {
        //granica da moze samo tri fotografije odjednom da se uploaduju

        if (this.oneImagedownloadUrl.length <= 2) {

            this.oneImageFiles = event.addedFiles

            const id = Math.random().toString(36).substring(2);
            this.refOneImage = this.afStorage.ref('/' + category + '/' + id);
            this.taskOneImage = this.refOneImage.put(event.addedFiles[0]);
            this.taskOneImage.snapshotChanges().pipe(
                finalize(() => {
                    this.refOneImage.getDownloadURL().subscribe(url => {
                        if (this.oneImagedownloadUrl[0] == '') this.oneImagedownloadUrl.pop()
                        this.oneImagedownloadUrl.push(url); // <-- do what ever you want with the url..
                        if (category === 'users')
                            this.saveChanges()
                    });
                }))
                .subscribe();
        }
    }


    deletePhotoFromFirebaseStorage(downloadUrl) {
        this.oneImagedownloadUrl = this.deletePhotoFromArray(downloadUrl)
        return this.afStorage.storage.refFromURL(downloadUrl).delete();
    }

    deletePhotoFromArray(downloadURL) {
        return this.oneImagedownloadUrl.filter(url => url !== downloadURL);
    }

    saveChanges() {
        this.authService.updateUserProfile(this.authService.logedUserUID(), this.oneImagedownloadUrl[0]);
        // this.sharedClass.deletePhotoFromFirebaseStorage(this.userObject.imageUrl)
        this.oneImagedownloadUrl = []
    }

    dateFormatddmmyyyy(){
        return new Date().getDate() + '.' + (new Date().getMonth() + 1) +'.'+ new Date().getFullYear() + '.';
    }
}