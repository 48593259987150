import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import { FormGroup, FormControl } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userList: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.userList = firebase.list("users");
  }

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    User: new FormControl(""),
  });

  getAllUsers() {
    this.userList = this.firebase.list("users");
    return this.userList.snapshotChanges();
  }

  deleteUserFromRealTimeDatabase(userUID) {
    this.userList.remove(userUID);
  }

  disableUser(uid) {
    return this.firebase.object("users/" + uid).update({
      AccountEnabled: false,
    });
  }

  enableUser(uid) {
    return this.firebase.object("users/" + uid).update({
      AccountEnabled: true,
    });
  }

  updateUserProfile(user) {
    return this.firebase.object("users/" + user.uid).update({
      firstname: user.firstname,
      lastname: user.lastname,
      role: user.role,
      School: user.School,
      adresa: user.adresa,
      brojLicneKarte: user.brojLicneKarte,
      brojTelefona1: user.brojTelefona1,
      brojTelefona2: user.brojTelefona2,
    });
  }
}
