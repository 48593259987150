import { InstrumentsService } from './../instruments.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Instrument } from '../instrument.model';

@Component({
  selector: 'app-instrument-detail',
  templateUrl: './instrument-detail.component.html',
  styleUrls: ['./instrument-detail.component.css']
})
export class InstrumentDetailComponent implements OnInit {


  instrument: Instrument;
  currentPhotoUrl: string = "";

  constructor(private instrumentService: InstrumentsService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  ngOnInit() {

    this.instrumentService.getInstrumentObjectBySelectedKey(this.data.instrumentKey).subscribe(item => {
      this.instrument = item
      this.currentPhotoUrl = item.imageUrls[0]
    });

  }

  showSelectedImage(event) {

    this.currentPhotoUrl = event.target.currentSrc;

  }

}