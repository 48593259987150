<button [useExistingCss]="true" mat-stroked-button color="primary" printSectionId="print-section" ngxPrint style="margin: 2%;">Štampaj</button>
<div style="padding: 5%;" id="print-section">
    <table>
        <thead>
            <tr>
                <td>
                    <div class="header-space"></div>
                    <h4>Izveštaj na nivou škole</h4>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="content">
                        <router-outlet></router-outlet>
                        <table>
                            <tr>
                                <th *ngIf="columnsForPrintingReport[0]">Red. br.</th>
                                <th *ngIf="columnsForPrintingReport[1]">Korisnik</th>
                                <th *ngIf="columnsForPrintingReport[2]">Email</th>
                                <th *ngIf="columnsForPrintingReport[3]">Naziv</th>
                                <th *ngIf="columnsForPrintingReport[4]">Datum izdavanja</th>
                                <th *ngIf="columnsForPrintingReport[5]">Vreme izdavanja</th>
                                <th *ngIf="columnsForPrintingReport[6]">Cena</th>
                                <th *ngIf="columnsForPrintingReport[7]">Status</th>
                            </tr>
                            <tr *ngFor="let report of listOfDataForPrinting; let i = index;">
                                <td *ngIf="columnsForPrintingReport[0]">{{i+1}}</td>
                                <td *ngIf="columnsForPrintingReport[1]">{{report?.User}}</td>
                                <td *ngIf="columnsForPrintingReport[2]">{{report?.Email}}</td>
                                <td *ngIf="columnsForPrintingReport[3]">{{report?.Naziv}}</td>
                                <td *ngIf="columnsForPrintingReport[4]">{{report?.IssuingDate}}</td>
                                <td *ngIf="columnsForPrintingReport[5]">{{report?.IssuingTime}}</td>
                                <td *ngIf="columnsForPrintingReport[6]">{{report?.Cena}}</td>
                                <td *ngIf="columnsForPrintingReport[7]">{{report?.Status}}</td>
                            </tr>
                        </table>

                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space"></div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<!-- <div *ngIf="columnsForPrinting.length==0">
    <h3>Nema ništa za štampanje.</h3>
</div> -->