<mat-card class="mat-elevation-z6" class="box" fxFlexAlign="stretch">
  <mat-card-header>
    <mat-card-title title="{{ instrument.Naziv }}"
      >{{ instrument.Naziv.substring(0, 15) }} ...</mat-card-title
    >
    <mat-card-subtitle>{{ instrument?.Proizvodjac }}</mat-card-subtitle>
    <mat-card-subtitle>{{ instrument?.School?.Naziv }}</mat-card-subtitle>
  </mat-card-header>
  <ul
    class="products"
    style="margin-left: -9%; cursor: pointer;"
    (click)="onOpenInstrumentDetail(instrument.$key, instrument.SchoolKey)"
  >
    <li class="crop" style="border: solid 1px black;">
      <img src="{{ instrument.imageUrls[0] }}" alt="Instrument" />
    </li>
  </ul>
  <mat-card-content>
    <p>
      Tip: <b>{{ instrument.Tip }} </b>
    </p>
    <p>
      Godina proizvodnje: <b> {{ instrument?.GodinaProizvodnje }}</b>
    </p>
    <p>
      Inventarski broj: <b> {{ instrument?.invetarskiBroj }}</b>
    </p>
    <p>
      Serijski broj: <b>{{ instrument?.serijskiBroj }}</b>
    </p>
    <p>
      Godina proizvodnje: <b> {{ instrument?.GodinaProizvodnje }}</b>
    </p>
    <p>
      Način nabavke: <b>{{ instrument?.NacinNabavke }}</b>
    </p>
    <p>
      Procenjena vrednost: <b>{{ instrument?.Cena }}</b> RSD
    </p>
    <p style="color: green;">Status: {{ instrument?.Status }}</p>
    <p>Prostorija: {{ instrument?.Prostorija }}</p>
    <p>Polica: {{ instrument?.Polica }}</p>
    <p>Komentar:</p>
    <p
      (click)="onOpenCommentView(instrument.Komentar)"
      style="cursor: pointer;"
    >
      {{ instrument?.Komentar.substring(0, 100) }} <b>... [više] </b>
    </p>
  </mat-card-content>
  <mat-card-actions style="text-align: center;">
    <button
      mat-button
      (click)="onOpenEditDialog(instrument.$key, instrument.SchoolKey)"
      *ngIf="
        instrument.SchoolKey === currentSchoolKey &&
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-button
      (click)="onOpenInstrumentDetail(instrument.$key, instrument.SchoolKey)"
    >
      <mat-icon>search</mat-icon>
    </button>
    <button
      *ngIf="
        instrument.Status === 'Za izdavanje' &&
        instrument.SchoolKey === currentSchoolKey &&
        loggedUser.role !== 'Eksterni korisnik' &&
        loggedUser.role !== 'Učenik' &&
        loggedUser.role !== 'Profesor'
      "
      mat-button
      (click)="onOpenInstrumentIssuing(instrument.$key, instrument.SchoolKey)"
    >
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
