<div id="print-instrument-section">
  <div style="display: flex; flex-flow: row wrap; margin: 1%;">
    <div>
      <div class="divImgMain" style="border: 2px solid #edf0ee; margin: 2%;">
        <img src="{{ currentPhotoUrl }}" />
      </div>

      <div fxLayout="row" style="justify-content: center;">
        <div
          *ngFor="let photo of instrument?.imageUrls"
          style="margin: 2%; border: 2px solid #edf0ee;"
        >
          <div class="divImg">
            <img
              src="{{ photo }}"
              (click)="showSelectedImage($event)"
              (mouseenter)="showSelectedImage($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <mat-divider style="margin-left: 3%;" [vertical]="true"></mat-divider>
    <div style="max-width: 400px; margin-left: 5%;">
      <p style="color: #696763; font-size: x-large;">{{ instrument?.Naziv }}</p>
      <p>
        Inventarski broj: <b> {{ instrument?.invetarskiBroj }} </b>
      </p>
      <p>
        Serijski broj: <b> {{ instrument?.serijskiBroj }} </b>
      </p>
      <p>
        Tip: <b> {{ instrument?.Tip }} </b>
      </p>
      <p>
        Proizvođač: <b> {{ instrument?.Proizvodjac }} </b>
      </p>
      <p>
        Godina: <b> {{ instrument?.GodinaProizvodnje }} </b>
      </p>
      <p>
        Način nabavke: <b> {{ instrument?.NacinNabavke }} </b>
      </p>
      <p>
        Procenjena vrednost: <b> {{ instrument?.Cena }} </b>
      </p>
      <p>
        Status: <b> {{ instrument?.Status }} </b>
      </p>
      <p>
        Prostorija: <b> {{ instrument?.Prostorija }} </b>
      </p>
      <p>
        Polica: <b> {{ instrument?.Polica }} </b>
      </p>
      <p style="font-size: smaller;">Komentar: {{ instrument?.Komentar }}</p>
    </div>
  </div>
</div>
<!-- <div style="text-align: center;">
    <button [useExistingCss]="true" mat-stroked-button color="primary" printSectionId="print-instrument-section" ngxPrint style="margin: 2%;">
            <mat-icon>print</mat-icon> Štampaj
        </button>
</div> -->
