<div [hidden]="!(user && userObject?.role === 'SuperAdmin' || userObject?.role === 'AdminSkole')" style="margin: 2%;">
    <mat-accordion mat-list-item>
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header style="background-color:#34b1eb;">
                <mat-panel-title style="color:white">
                    <p>Pregled izdavanja</p>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div style="display: flex; flex-flow:row wrap;justify-content: center;margin:1%;background-color:#34b1eb; border-radius: 10px; ">
                <div class="statistic" style="padding: 1%; font-size: xx-large;">

                    Ukupno: <b> {{statistics.totalIssuedInstruments}} </b>
                    <mat-icon>queue_music</mat-icon>
                </div>
                <div class="statistic" style="padding: 1%; font-size: xx-large;">
                    Razduženih: <b> {{statistics.totalBackInstruments}} </b>
                    <mat-icon style="color: green;">done_outline</mat-icon>
                </div>
                <div class="statistic" style="padding: 1%; font-size: xx-large;">
                    Zauzeto: <b> {{statistics.totalIssuedInstruments - statistics.totalBackInstruments}} </b>
                    <mat-icon style="color: red;">weekend</mat-icon>
                </div>
            </div>

            <div class="mat-elevation-z14 table" style="margin: 2%;">
                <h2 style="text-align: center;">Stanje instrumentarijuma</h2>
                <div fxLayout="row">
                    <div style="width:100% !important; margin: 1%;">
                        <mat-form-field class="search-form-field" style="width:100% !important;">
                            <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
                            <button mat-button *ngIf="searchKey" matSuffix mat-icon-button aria-label="Clear" (click)="searchKey='';
                                     applyFilter();">
                                <mat-icon>close</mat-icon>
                            </button >
                        </mat-form-field>
                        <div>
                            <mat-card>
                                
                            <button mat-stroked-button style="margin: 1%;" (click)="onTakenInstrument()">Zauzet </button>
                            <button mat-stroked-button style="margin: 1%;" (click)="onBackenInstrument()"> Razdužen </button>
                            <button mat-stroked-button style="margin: 1%;" (click)="loadAllIssues()"> <mat-icon>undo</mat-icon>  </button>
                            <button mat-stroked-button style="margin: 1%;" (click)="onOpenPrintConfiguration()"> <mat-icon>print</mat-icon>  </button>
                            </mat-card>
                    </div>
                </div>
            </div>
            <mat-table [dataSource]="listData" matSort>
                <ng-container matColumnDef="Naziv" style="margin-left: 5%;">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="margin-left: 5%;"> Naziv </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="margin-left: 5%;">{{element.Naziv}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Korisnik">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Korisnik </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index"> <b>{{i+1}}.&nbsp;  </b>{{element.User}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="margin-left: 5%;"> Datum izdavanja </mat-header-cell>
                    <mat-cell *matCellDef="let element;" style="margin-left: 5%;">{{element.IssuingDate}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Vreme izdavanja </mat-header-cell>
                    <mat-cell *matCellDef="let element;">{{element.IssuingTime}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Email" style="margin-left: 5%;">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="margin-left: 5%;"> Email </mat-header-cell>
                    <mat-cell *matCellDef="let element;" style="margin-left: 5%;">{{element.Email}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="Status" style="margin-left: 10%;">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element;">
                        <div *ngIf="element.Status !== 'Zauzet'" style="color: green;">
                            <b>{{element.Status.substring(9, 19)}} </b>
                        </div>
                        <div *ngIf="element.Status === 'Zauzet'" style="color: red;">
                            <b>{{element.Status}} </b>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Options">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                    <mat-cell *matCellDef="let element;">
                        <div fxLayout="row" style="margin-left: 10%;">
                            <button mat-stroked-button (click)="onOpenInstrumentDetail(element.instrumentKey)"> <mat-icon>search</mat-icon></button>
                            <button mat-stroked-button (click)="onOpenIssuingDetail(element, element.IssuingKey)"> <mat-icon>date_range</mat-icon></button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns : displayedColumns"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5,10,25,100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
            <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </mat-card>
</div>
</mat-expansion-panel>
</mat-accordion>
</div>