<div style="display: flex; flex-flow:row wrap;">
    <div>
        <button class="fill" mat-stroked-button routerLink='/pregledInstrumenata'>
      <mat-icon>list</mat-icon> Detaljno
    </button>
    </div>
    <div>
        <button mat-stroked-button routerLink='/tabelaInstrumenti' *ngIf="loggedUser.role === 'SuperAdmin' ||  loggedUser.role  === 'AdminSkole'">
      <mat-icon>table_chart</mat-icon> Tabelarno
    </button>
    </div>
    <div style="width:70%; margin-left: 2%">
        <mat-form-field class="example-full-width" style="width:100% !important;">
            <input matInput placeholder="Pretraži" value="" #searchInput (keyup)="searchInstruments()">
        </mat-form-field>
    </div>
</div>
<div>
    <mat-card>
        <div style="display: flex; flex-flow:row wrap; margin: 1%;justify-content: center;">
            <mat-form-field style="margin-left:2%">
                <mat-label> Proizvodjač</mat-label>
                <mat-select [(ngModel)]="selectedNazivProizvodjaca">
                    <mat-option *ngFor="let instrumentMakerElement of instrumentMakers" [value]="instrumentMakerElement.Naziv">
                        <mat-checkbox #myCheckbox (click)="$event.stopPropagation(); takeElement(instrumentMakerElement.Naziv);"></mat-checkbox>
                        {{instrumentMakerElement.Naziv}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left:2%">
                <mat-label> Tip</mat-label>
                <mat-select [(ngModel)]="selectedTip">
                    <mat-option *ngFor="let instrumentTypeElement of instrumentTypes" [value]="instrumentTypeElement.Naziv">
                        {{instrumentTypeElement.Naziv}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left:2%">
                <mat-label> Instrument </mat-label>
                <mat-select [(ngModel)]="selectedNaziv">
                    <mat-option *ngFor="let instrumentElement of instrumentsName" [value]="instrumentElement.Naziv">
                        {{instrumentElement.Naziv}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left:2%">
                <mat-label> Status</mat-label>
                <mat-select [(ngModel)]="selectedStatus">
                    <mat-option *ngFor="let instrumentStatusElement of instrumentStatuses" [value]="instrumentStatusElement.Naziv">
                        {{instrumentStatusElement.Naziv}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div style="margin-left:2%; ">
                <mat-form-field>
                    <mat-label>Škole</mat-label>
                    <mat-select [(value)]="selectedSve">
                        <mat-option (click)="loadInstrumentsFromEverySchool()" value="Sve">Sve</mat-option>
                        <mat-option *ngFor="let option of skole" (click)="getInstrumentsBySelectedSchool(option.Naziv)" value="option.Naziv">
                            {{option.Naziv}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button (click)="onSearchAfterSelectedParameters()" mat-stroked-button>Pretraži</button>
            <button (click)="loadToCurrentSchool(); resetingCheckBoxes()" mat-stroked-button>
                <mat-icon>cached</mat-icon> Resetuj pretragu
            </button>
        </div>
    </mat-card>

</div>
<div style="padding: 1%;">

    <mat-card *ngIf="isLoading" style=" display: flex; justify-content: center; align-items: center ">
        <mat-spinner></mat-spinner>
    </mat-card>

    <mat-card *ngIf="filteredInstruments.length === 0">
        <p style="text-align: center; color: red;">Trenutno nemate ubačenih instrumenata</p>
    </mat-card>
    <div style="display: flex; flex-flow:row wrap;justify-content: center; ">
        <div style="padding: 1%; " *ngFor="let instrumentEl of filteredInstruments ">
            <app-instrument-item [instrument]="instrumentEl">
            </app-instrument-item>
        </div>
    </div>
</div>
<button style="position: fixed; bottom: 0px; right: 0px; " mat-stroked-button [ngx-scroll-to]=" '#destination' ">
  <mat-icon>keyboard_arrow_up</mat-icon>
</button>