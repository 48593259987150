import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../auth/login/login.component';
import { AuthService } from '../auth/auth.service';
import { ThrowStmt } from '@angular/compiler';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit {

  user: firebase.User;
  userObject: User = {}
  currentYear = new Date().getFullYear();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog, public authService: AuthService,
    private router: Router) {

    this.authService.getUserState()
      .subscribe(user => {
        this.user = user;
      })
  }

  ngOnInit() {

    this.authService.getUserState()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.authService.getObjectOfLoggedUser(user.uid).subscribe(item => {
            this.authService.logedUser = item
            this.authService.logedUser.key = this.user.uid;
          });
        }
      })

  }

  logout(){
    this.authService.logedUser = {};
    this.authService.logout();
  }

  onOpenLoginDialog() {
    this.dialog.open(LoginComponent, {
      disableClose: false,
      autoFocus: true,
      width: '300px',
      height: '',

      data: {
      }
    });
  }
}
