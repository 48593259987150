import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import { MatDialog, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ThrowStmt } from '@angular/compiler';
import { SharedClass } from 'src/app/shared/shared-functions';
import { InstrumentsService } from 'src/app/instruments/instruments.service';
import { InstrumentDetailComponent } from 'src/app/instruments/instrument-detail/instrument-detail.component';
import { IssuingDetailComponent } from 'src/app/instruments/instrument-issuing/issuing-detail/issuing-detail.component';

interface Statistics {
  totalIssuedInstruments: number;
  totalBackInstruments: number;
}


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent implements OnInit {


  user: firebase.User;
  userObject: User;
  userIssues: any[] = [];
  allIssues: any[] = [];
  mappedIssues: { index: number, key: string }[] = [];
  filteredIssuedInstrument: any[] = [];
  @ViewChild('ownIssueSearchInput', { static: false }) inputOwnIssue: any;

  filteredDataForUser: any[] = []
  filteredDataForSchool: any[] = []
  files: any;

  showDeletedMessage: boolean;
  searchText: string = "";
  listData: MatTableDataSource<any>;
  isLoading = true;
  searchKey = '';
  clickedClear = 'clicked';
  displayedColumns: string[] = ['Index', 'Naziv', 'Date', 'Time', 'Cena', 'Status', 'Options'];
  statistics: Statistics = { totalBackInstruments: 0, totalIssuedInstruments: 0 };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  constructor(private auth: AuthService,
    private router: Router, private dialog: MatDialog,
    public sharedClass: SharedClass,
    private instrumentService: InstrumentsService
  ) { }

  ngOnInit() {
    this.auth.getUserState()
      .subscribe(user => {
        this.user = user;
        this.loggedUserID = user.uid;
        if (this.user) {
          this.auth.getObjectOfLoggedUser(user.uid).subscribe(item => {
            this.userObject = item;
            this.loadUserIssues(item.School.key);
            this.filteredIssuedInstrument = [];
          });
          this.dialog.closeAll();
          this.sharedClass.oneImagedownloadUrl = [];
        }
      })

  }

  loadUserIssues(schoolId) {

    return this.instrumentService.getInstrumentIssuingByUser(schoolId).subscribe(
      list => {
        this.userIssues = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
        this.filterIssuesForLoggedSchool();
      }
    );
  }


  public loggedUserID = "";
  temp = [];


  filterIssuesForLoggedSchool() {


    this.userIssues.forEach(element => {


      if (element.User.key === this.loggedUserID) {

        this.temp.push({

          instrumentKey: element.Instrument.key,
          Naziv: element.Instrument.Naziv,
          Cena: element.Instrument.Cena,
          User: element.User.imePrezime,
          IssuingDate: element.Instrument.IssuingDate,
          IssuingTime: element.Instrument.IssuingTime,
          Email: element.User.email,
          IssuingKey: element.key,
          Status: element.Instrument.Status,
          brLicneKarte: element.User.brLicneKarte,
          adresa: element.User.adresa,
          UserWhoIssueTheInstrument: element.Instrument.UserWhoIssueTheInstrument.Name,
          Proizvodjac: element.Instrument.Proizvodjac,
          serijskiBroj: element.Instrument.serijskiBroj,
          invetarskiBroj: element.Instrument.invetarskiBroj,
        })

      }


    });

    this.listData = new MatTableDataSource(this.temp);
    this.listData.sort = this.matSort;
    this.listData.paginator = this.paginator;
    this.isLoading = false;

    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status !== 'Zauzet').length;
  }



  searchOwnIssues(searchWord?) {

    let searchInput = this.searchKey.toString().toLowerCase();

    if (searchWord)
      searchInput = searchWord.toLowerCase();

    return this.listData.data = this.temp.filter((item: any) => {
      return item.Naziv.toLowerCase().includes(searchInput)
        || item.Cena.toLowerCase().includes(searchInput)
        || item.IssuingTime.toLowerCase().includes(searchInput)
        || item.Status.toLowerCase().includes(searchInput)
        || item.IssuingDate.toLowerCase().includes(searchInput)
    })

  }

  login() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.auth.logedUser = {};
    this.userIssues = [];
    this.filteredDataForUser = [];
    this.auth.logout();
  }

  register() {
    this.router.navigate(['/register']);
  }

  uploadImage(event) {
    this.sharedClass.uploadImage(event, 'users');
  }

  deletePhoto() {
    this.auth.updateUserProfile(this.user.uid, '');
    this.sharedClass.deletePhotoFromFirebaseStorage(this.userObject.imageUrl)
  }

  resetPassword() {
    if (this.auth.resetPassword(this.userObject.email))
      this.sharedClass.openSnackBar('Poruka', 'Link za reset lozinke vam je poslat na email adresu: ' + this.userObject.email)
  }

  onTakenInstrument() {
    this.userIssues = this.searchOwnIssues('Zauzet');
    this.calculateStatistics();
  }

  onBackenInstrument() {
    this.userIssues = this.searchOwnIssues('Razdužen');
    this.calculateStatistics();
  }

  applyFilter() {
    this.userIssues = this.searchOwnIssues();
    this.calculateStatistics();
  }

  calculateStatistics() {
    this.statistics.totalIssuedInstruments = this.listData.filteredData.length;
    this.statistics.totalBackInstruments = this.listData.filteredData.filter(x => x.Status !== 'Zauzet').length;
  }

  onOpenInstrumentDetail(key) {
    this.dialog.open(InstrumentDetailComponent, {
      disableClose: false,
      autoFocus: true,
      maxWidth: '90%',
      height: '600px',
      data: {
        instrumentKey: key
      }
    });
  }

  onOpenIssuingDetail(issue?) {

    this.dialog.open(IssuingDetailComponent, {
      disableClose: false,
      autoFocus: true,
      width: '',
      height: '',
      data: {
        issue: issue,
      }
    });

  }
}
