<div style="display: flex; flex-flow:row wrap; margin: 1%;" fxLayoutGap="20px" *ngIf="user">
    <div style="margin:2%;">
        <mat-card *ngIf="user">
            <div style="text-align: center;">
                <h2> {{userObject?.role}} - {{userObject?.School.Naziv}}</h2>
                <h2 style="color: red;" *ngIf="!userObject?.AccountEnabled"> Vaš profil je deaktiviran, za aktivaciju obratite se nadležnom administratoru.</h2>
            </div>
            <div style="display: flex; flex-flow:row wrap; margin-top:2%; justify-content: center;">
                <div *ngIf="userObject?.imageUrl" style="text-align: center;">
                    <img style="  max-width: 40%; height: auto;" mat-card-image src="{{userObject?.imageUrl}}">
                </div>
                <div *ngIf="this.sharedClass.oneImagedownloadUrl.length > 0" style="margin-right: 2%;">
                    <img style="  max-width: 40%; height: auto;" mat-card-image src="{{this.sharedClass.oneImagedownloadUrl}}">
                </div>

                <div *ngIf="this.sharedClass.oneImagedownloadUrl.length == 0 && !userObject?.imageUrl" style="margin-right: 2%;">
                    <ngx-dropzone (change)="uploadImage($event)">
                        <ngx-dropzone-label>Dodajte fotografiju ili izmenite postojeću</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>
                </div>
                <div style="text-align: center;">
                    <button *ngIf="userObject?.imageUrl" mat-stroked-button (click)="deletePhoto()" style="margin: 0 auto;"><mat-icon>delete</mat-icon>Fotografija</button>
                    <p *ngIf="user">Ime i prezime: <b> {{ userObject?.firstname }}  {{userObject?.lastname}} </b></p>
                    <p *ngIf="user">Email: <b>  {{ user?.email }} </b> </p> <br><br><br><br><br>
                    <button mat-stroked-button *ngIf="user" style=" margin-right: 10%;"> <mat-icon>search</mat-icon></button>
                    <button mat-stroked-button *ngIf="user" (click)="logout()" style="color: red;"> <mat-icon>keyboard_tab</mat-icon></button>
                    <button mat-stroked-button *ngIf="user" (click)="resetPassword()" style="margin-top:3%"><mat-icon>screen_lock_landscape</mat-icon> Resetuj lozinku  </button>
                </div>
            </div>
        </mat-card>
    </div>
    <div style="margin: 2%;" class="tableDiv">
        <mat-accordion mat-list-item style="margin: 2%;">
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header style="background-color:#34b1eb;">
                    <mat-panel-title style="color:white">
                        <p>Pregled izdavanja</p>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div style="display: flex; flex-flow:row wrap;justify-content: center;margin:1%;background-color:#34b1eb; border-radius: 10px; ">
                    <div class="statistic" style="padding: 1%; font-size: xx-large;">

                        Ukupno: <b> {{statistics.totalIssuedInstruments}} </b>
                        <mat-icon>queue_music</mat-icon>
                    </div>
                    <div class="statistic" style="padding: 1%; font-size: xx-large;">
                        Razduženih: <b> {{statistics.totalBackInstruments}} </b>
                        <mat-icon style="color: green;">done_outline</mat-icon>
                    </div>
                    <div class="statistic" style="padding: 1%; font-size: xx-large;">
                        Zauzeto: <b> {{statistics.totalIssuedInstruments - statistics.totalBackInstruments}} </b>
                        <mat-icon style="color: red;">weekend</mat-icon>
                    </div>
                </div>

                <div class="mat-elevation-z14 table" style="margin: 2%;">
                    <h2 style="text-align: center;">Istorija ličnih izdavanja</h2>
                    <div fxLayout="row">
                        <div style="width:100% !important; margin: 1%;">
                            <mat-form-field class="search-form-field" style="width:100% !important;">
                                <input matInput [(ngModel)]="searchKey" placeholder="Search" autocomplete="off" (keyup)="applyFilter()">
                                <button mat-button *ngIf="searchKey" matSuffix mat-icon-button aria-label="Clear" (click)="searchKey='';
                                         applyFilter();">
                                    <mat-icon>close</mat-icon>
                                </button >
                            </mat-form-field>
                            <div>
                                <mat-card>
                                    
                                <button mat-stroked-button style="margin: 1%;" (click)="onTakenInstrument()">Zauzet </button>
                                <button mat-stroked-button style="margin: 1%;" (click)="onBackenInstrument()"> Razdužen </button>
                                <button mat-stroked-button style="margin: 1%;" (click)="loadUserIssues(user.uid)"> <mat-icon>undo</mat-icon>  </button>
                                </mat-card>
                        </div>
                    </div>
                </div>
                <mat-table [dataSource]="listData" matSort>
                    <ng-container matColumnDef="Naziv">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Naziv </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element?.Naziv}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Cena">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Cena </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element?.Cena}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Index">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Index </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index">{{i+1}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Datum izdavanja </mat-header-cell>
                        <mat-cell *matCellDef="let element;">{{element?.IssuingDate}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Time">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Vreme izdavanja </mat-header-cell>
                        <mat-cell *matCellDef="let element;">{{element?.IssuingTime}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Status">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <div *ngIf="element.Status !== 'Zauzet'" style="color: green;">
                                <b>{{element?.Status.substring(9, 19)}} </b>
                            </div>
                            <div *ngIf="element.Status === 'Zauzet'" style="color: red;">
                                <b>{{element?.Status}} </b>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Options">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                        <mat-cell *matCellDef="let element;">
                            <div fxLayout="row" style="margin-left: 10%;">
                                <button mat-stroked-button (click)="onOpenInstrumentDetail(element.instrumentKey)"> <mat-icon>search</mat-icon></button>
                                <button mat-stroked-button (click)="onOpenIssuingDetail(element)"> <mat-icon>date_range</mat-icon></button>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns : displayedColumns"></mat-row>
                </mat-table>
                <mat-paginator [pageSizeOptions]="[5,10,25,100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
                <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
                    <mat-progress-spinner color="primary" mode="indeterminate">
                    </mat-progress-spinner>
                </mat-card>
    </div>
    </mat-expansion-panel>
    </mat-accordion>
</div>
</div>