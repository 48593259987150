import { Component, OnInit } from '@angular/core';
import { InstrumentsService } from '../instruments.service';
import { Instrument } from '../instrument.model';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {

  instruments: Instrument[];
  columnsForPrinting: any;
  dataForPrinting: any = [];

  nazivExists: boolean = false;
  invetarskiBrojExists: boolean = false;
  serijskiBrojExists: boolean = false;
  TipExists: boolean = false;
  ProizvodjacExists: boolean = false;
  GodinaProizvodnjeExists: boolean = false;
  NacinNabavkeExists: boolean = false;
  CenaExists: boolean = false;
  StatusExists: boolean = false;
  ProstorijaExists: boolean = false;
  KomentarExists: boolean = false;

  constructor(private instrumentService: InstrumentsService,
      public authService: AuthService) { }

  ngOnInit() {
    this.loadInstruments();
    this.columnsForPrinting = this.instrumentService.columnsForPrinting;
    this.dataForPrinting = this.instrumentService.dataFromTableForPrinting;
    this.checkWhatExists();

  }

  loadInstruments() {
    this.instrumentService.getInstrumentSamples().subscribe(
      list => {
        this.instruments = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    )
  }

  checkWhatExists() {
    if (this.columnsForPrinting.filter(x => x == 'Naziv').length > 0)
      this.nazivExists = true;
    if (this.columnsForPrinting.filter(x => x == 'invetarskiBroj').length > 0)
      this.invetarskiBrojExists = true;
    if (this.columnsForPrinting.filter(x => x == 'serijskiBroj').length > 0)
      this.serijskiBrojExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Tip').length > 0)
      this.TipExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Proizvodjac').length > 0)
      this.ProizvodjacExists = true;
    if (this.columnsForPrinting.filter(x => x == 'GodinaProizvodnje').length > 0)
      this.GodinaProizvodnjeExists = true;
    if (this.columnsForPrinting.filter(x => x == 'NacinNabavke').length > 0)
      this.NacinNabavkeExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Cena').length > 0)
      this.CenaExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Status').length > 0)
      this.StatusExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Prostorija').length > 0)
      this.ProstorijaExists = true;
    if (this.columnsForPrinting.filter(x => x == 'Komentar').length > 0)
      this.KomentarExists = true;

  }

}
