import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';

export class Role {
  Naziv: string;
}

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  authError: any;
  user: User = {};
  roles: Role[] = [];
  schools: any[] = [];
  selectedRola: string = '';
  @ViewChild('selectedSkola', { static: true }) inputNazivSkole: any;
  selectedSkola: string = '';
  userObject: User = {}


  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.getMusicalSchools();
    this.auth.getRoles().subscribe(
      list => {
        this.roles = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    )

    this.auth.eventAuthError$.subscribe(data => {
      this.authError = data;
    })

    this.auth.getUserState()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.auth.getObjectOfLoggedUser(user.uid).subscribe(item => this.userObject = item);
        }
      })
  }



  getMusicalSchools() {
    this.auth.getMusicalSchools().subscribe(
      list => {
        this.schools = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    )
  }

  createUser(frm) {

    if (this.userObject.role === 'SuperAdmin') {
      let selectedSchool = this.schools.filter(item => item.Naziv === this.selectedSkola);
      this.auth.createUser(frm.value, this.selectedRola, this.auth.logedUserUID(), selectedSchool[0]);
    }
    else if (this.userObject.role === 'AdminSkole') {
      let currentSchool: { Naziv: string, $key: string } = { Naziv: this.userObject.School.Naziv, $key: this.userObject.School.key }
      this.auth.createUser(frm.value, this.selectedRola, this.auth.logedUserUID(), currentSchool);
    }

  }

}