import { FormGroup, FormControl } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
export class UserService {
    constructor(firebase) {
        this.firebase = firebase;
        this.form = new FormGroup({
            $key: new FormControl(null),
            User: new FormControl(""),
        });
        this.userList = firebase.list("users");
    }
    getAllUsers() {
        this.userList = this.firebase.list("users");
        return this.userList.snapshotChanges();
    }
    deleteUserFromRealTimeDatabase(userUID) {
        this.userList.remove(userUID);
    }
    disableUser(uid) {
        return this.firebase.object("users/" + uid).update({
            AccountEnabled: false,
        });
    }
    enableUser(uid) {
        return this.firebase.object("users/" + uid).update({
            AccountEnabled: true,
        });
    }
    updateUserProfile(user) {
        return this.firebase.object("users/" + user.uid).update({
            firstname: user.firstname,
            lastname: user.lastname,
            role: user.role,
            School: user.School,
            adresa: user.adresa,
            brojLicneKarte: user.brojLicneKarte,
            brojTelefona1: user.brojTelefona1,
            brojTelefona2: user.brojTelefona2,
        });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AngularFireDatabase)); }, token: UserService, providedIn: "root" });
