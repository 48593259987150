import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
import * as i2 from "@angular/fire/storage";
import * as i3 from "../auth/auth.service";
export class SharedClass {
    constructor(_snackBar, afStorage, authService) {
        this._snackBar = _snackBar;
        this.afStorage = afStorage;
        this.authService = authService;
        this.ref = [];
        this.task = [];
        this.downloadURLs = [];
        this.fileCounter = 0;
        this.progressValue = 0;
        this.uploadedBoolean = true;
        this.downloadUrl = '';
        this.oneImagedownloadUrl = [];
    }
    openSnackBar(message, action) {
        this._snackBar.open(message, action, {
            duration: 5000,
        });
    }
    uploadImages(event, category) {
        this.progressValue = 0;
        this.imageFiles = event.addedFiles;
        for (let i = 0; i < this.imageFiles.length; i++) {
            const id = Math.random().toString(36).substring(2) + i;
            this.ref[i] = this.afStorage.ref('/' + category + '/' + id);
            this.task[i] = this.ref[i].put(event.addedFiles[i]);
            this.task[i].snapshotChanges().pipe(finalize(() => {
                this.ref[i].getDownloadURL().subscribe(url => {
                    this.currentFileName = this.imageFiles[i].name;
                    this.fileCounter++;
                    if (this.fileCounter <= this.imageFiles.length) {
                        let temp = 100 / this.imageFiles.length;
                        this.progressValue += temp;
                        if (this.fileCounter == this.imageFiles.length)
                            this.uploadedBoolean = true;
                    }
                    this.downloadURLs[i] = url;
                    //console.log(url)// <-- do what ever you want with the url..
                });
            }))
                .subscribe();
        }
    }
    uploadImage(event, category) {
        //granica da moze samo tri fotografije odjednom da se uploaduju
        if (this.oneImagedownloadUrl.length <= 2) {
            this.oneImageFiles = event.addedFiles;
            const id = Math.random().toString(36).substring(2);
            this.refOneImage = this.afStorage.ref('/' + category + '/' + id);
            this.taskOneImage = this.refOneImage.put(event.addedFiles[0]);
            this.taskOneImage.snapshotChanges().pipe(finalize(() => {
                this.refOneImage.getDownloadURL().subscribe(url => {
                    if (this.oneImagedownloadUrl[0] == '')
                        this.oneImagedownloadUrl.pop();
                    this.oneImagedownloadUrl.push(url); // <-- do what ever you want with the url..
                    if (category === 'users')
                        this.saveChanges();
                });
            }))
                .subscribe();
        }
    }
    deletePhotoFromFirebaseStorage(downloadUrl) {
        this.oneImagedownloadUrl = this.deletePhotoFromArray(downloadUrl);
        return this.afStorage.storage.refFromURL(downloadUrl).delete();
    }
    deletePhotoFromArray(downloadURL) {
        return this.oneImagedownloadUrl.filter(url => url !== downloadURL);
    }
    saveChanges() {
        this.authService.updateUserProfile(this.authService.logedUserUID(), this.oneImagedownloadUrl[0]);
        // this.sharedClass.deletePhotoFromFirebaseStorage(this.userObject.imageUrl)
        this.oneImagedownloadUrl = [];
    }
    dateFormatddmmyyyy() {
        return new Date().getDate() + '.' + (new Date().getMonth() + 1) + '.' + new Date().getFullYear() + '.';
    }
}
SharedClass.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedClass_Factory() { return new SharedClass(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i2.AngularFireStorage), i0.ɵɵinject(i3.AuthService)); }, token: SharedClass, providedIn: "root" });
