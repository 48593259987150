<div>
    <div>
        <form (keydown.enter)="login();">

            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <input matInput #email type="email" placeholder="Email">
                </mat-form-field>
            </form>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <input matInput #password type="password" placeholder="Lozinka">
                </mat-form-field>
            </form>
            <div *ngIf="authError">
                <h5 style="color:red">Uneli ste pogrešan email ili lozinku.</h5>
            </div>
            <div>
                <div>
                    <button type="button" mat-stroked-button (click)="login();">Login</button>
                </div>
            </div>
        </form>
    </div>
</div>