import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import {
  AngularFireList,
  AngularFireDatabase,
  AngularFireObject,
} from "angularfire2/database";
import { MatDialog } from "@angular/material";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  usersList: AngularFireList<any>;
  roleList: AngularFireList<any>;
  schoolList: AngularFireList<any>;

  userObject: AngularFireObject<User>;
  newUser: any = {};
  logedUser: any = {};

  constructor(
    private afAuth: AngularFireAuth,
    private firebase: AngularFireDatabase,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.usersList = firebase.list("users");
    this.roleList = firebase.list("Role");
    this.schoolList = firebase.list("Skole");
  }

  getRoles() {
    this.roleList = this.firebase.list("Role");
    return this.roleList.snapshotChanges();
  }

  getMusicalSchools() {
    this.schoolList = this.firebase.list("Skole");
    return this.schoolList.snapshotChanges();
  }

  getUserState() {
    return this.afAuth.authState;
  }

  login(email: string, password: string) {
    this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => {
        this.eventAuthError.next(error);
      })
      .then((userCredential) => {
        if (userCredential) {
          this.router.navigate(["/pregledInstrumenata"]);
        }
      });
  }

  createUser(user, Role, logedUserUID, selectedSchool) {
    this.afAuth.auth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        this.newUser = user;
        userCredential.user.updateProfile({
          displayName: user.firstName + " " + user.lastName,
        });

        this.insertUserData(
          userCredential,
          Role,
          logedUserUID,
          selectedSchool,
          user
        ).then(() => {
          location.reload();
          this.router.navigate(["/pregledInstrumenata"]);
        });
      })
      .catch((error) => {
        this.eventAuthError.next(error);
      });
  }

  insertUserData(
    userCredential: firebase.auth.UserCredential,
    Role: string,
    logedUserUID,
    selectedSchool,
    user?
  ) {
    console.log(user);
    if (selectedSchool != null)
      return this.firebase.object("users/" + userCredential.user.uid).set({
        email: this.newUser.email,
        firstname: this.newUser.firstName,
        lastname: this.newUser.lastName,
        role: Role,
        CreatedByUID: logedUserUID,
        School: {
          key: selectedSchool.$key,
          Naziv: selectedSchool.Naziv,
        },
        AccountEnabled: true,
        PhoneNumber1: user.phoneNumber1,
      });
    else
      return this.firebase.object("users/" + userCredential.user.uid).set({
        email: this.newUser.email,
        firstname: this.newUser.firstName,
        lastname: this.newUser.lastName,
        role: Role,
        CreatedByUID: logedUserUID,
        AccountEnabled: true,
      });
  }

  logout() {
    this.router.navigate([""]);
    return this.afAuth.auth.signOut();
  }

  getObjectOfLoggedUser($key: string) {
    this.userObject = this.firebase.object("users/" + $key);
    return this.userObject.valueChanges();
  }

  logedUserUID() {
    return this.afAuth.auth.currentUser.uid;
  }

  updateUserProfile(uid, imageUrl) {
    return this.firebase.object("users/" + uid).update({
      imageUrl: imageUrl,
    });
  }

  resetPassword(email: string) {
    return this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error));
  }

  isAuthenticated() {
    return this.afAuth.auth.currentUser ? true : false;
  }
}
