import { Component, OnInit } from '@angular/core';
import { InstrumentsService } from '../../instruments.service';

@Component({
  selector: 'app-printreport-layout',
  templateUrl: './printreport-layout.component.html',
  styleUrls: ['./printreport-layout.component.css']
})
export class PrintreportLayoutComponent implements OnInit {


  listOfDataForPrinting: any[] = [];
  columnsForPrintingReport: any[] = [];

  constructor(private instrumentService: InstrumentsService) { }

  ngOnInit() {

    this.listOfDataForPrinting = this.instrumentService.dataForPrintingReport;
    this.columnsForPrintingReport = this.instrumentService.columnsForPrintingReport;
    
  }

}
