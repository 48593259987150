import { InstrumentTableComponent } from './../../instrument-table/instrument-table.component';
import { InstrumentMaker, InstrumentType, InstrumentPurchaseType, InstrumentStatus } from './../../instrument-edit/instrument-edit.component';
import { InstrumentsService } from './../../instruments.service';
import { Component, OnInit, Inject } from '@angular/core';
import { Instrument } from '../../instrument.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-print-configuration',
  templateUrl: './print-configuration.component.html',
  styleUrls: ['./print-configuration.component.css']
})
export class PrintConfigurationComponent implements OnInit {

  instrumentMakers: InstrumentMaker[] = [];
  instrumentTypes: InstrumentType[] = [];
  instruments: Instrument[] = [];
  instrumentPurchaseTypes: InstrumentPurchaseType[] = [];
  instrumentStatuses: InstrumentStatus[] = [];

  instrumentMakerChecked = true;
  instrumentTypeChecked = true;
  instrumentChecked = true;
  instrumentPurchaseChecked = true;
  instrumentStatusChecked = true;

  constructor(private instrumentService: InstrumentsService,
    private dialog: MatDialog, private dialogRef: MatDialogRef<InstrumentTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  ngOnInit() {
    this.loadInstrumentMakers();
    this.loadInstrumentTypes();
    this.loadInstruments();
    this.loadInstrumentPurchaseTypes();
    this.loadInstrumentStatuses();
  }


  loadInstruments() {
    this.instrumentService.getInstruments().subscribe(
      list => {
        this.instruments = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentMakers() {
    this.instrumentService.getInstrumentMakers().subscribe(
      list => {
        this.instrumentMakers = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    )
  }

  loadInstrumentTypes() {
    this.instrumentService.getInstrumentTypes().subscribe(
      list => {
        this.instrumentTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    )
  }


  loadInstrumentPurchaseTypes() {
    this.instrumentService.getInstrumentPurchaseType().subscribe(
      list => {
        this.instrumentPurchaseTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentStatuses() {
    this.instrumentService.getInstrumentStatuses().subscribe(
      list => {
        this.instrumentStatuses = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  onChangeMaker(event) {
    (event.checked) ? this.instrumentMakerChecked = true : this.instrumentMakerChecked = false;
  }
  onChangeType(event) {
    (event.checked) ? this.instrumentTypeChecked = true : this.instrumentTypeChecked = false;
  }
  onChangeInstrument(event) {
    (event.checked) ? this.instrumentChecked = true : this.instrumentChecked = false;
  }
  onChangePurchase(event) {
    (event.checked) ? this.instrumentPurchaseChecked = true : this.instrumentPurchaseChecked = false;
  }
  onChangeStatus(event) {
    (event.checked) ? this.instrumentStatusChecked = true : this.instrumentStatusChecked = false;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkBoxChange(event, item) {
    if (!event.checked)
      this.instrumentService.columnsForPrinting = this.instrumentService.columnsForPrinting.filter(x => x !== item)
    else
      this.instrumentService.columnsForPrinting.push(item);
  }

}