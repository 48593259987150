<div fxLayout="column" style="max-width: 100%; overflow-y: auto;">
  <div>
    <h3>Izdavanje instrumenta - {{ userObject?.School?.Naziv }}</h3>
  </div>
  <div [formGroup]="this.userService.form">
    <mat-form-field style="width: 100% !important;" class="example-full-width">
      <input
        ng-model="Naziv"
        (selectedTabChange)="onOpenReverse()"
        tab-directive
        (keyup)="filterUsers(); takeUsers()"
        type="text"
        formControlName="User"
        #ImePrezime
        placeholder="Ime i prezime"
        aria-label="Number"
        matInput
        [formControl]="myControlUser"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptionsUser | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div style="text-align: center;">
      <button mat-stroked-button (click)="onOpenReverse()">
        <mat-icon>create</mat-icon> Kreiraj revers
      </button>
    </div>
  </div>
  <div *ngIf="selectedUser">
    <div>
      <div style="text-align: center;">
        <h3>REVERS</h3>
        <p>o izdavanju instrumenata muzičkih instrumenata škole</p>
      </div>
      <div>
        <p>Ovim reversom se potvrđuje da je <b>korisnik</b> instrumentarija:</p>
        <p>
          <b> Ime i prezime:</b> {{ selectedUser?.firstname }}
          {{ selectedUser?.lastname }}
        </p>
        <p><b> Broj lične karte:</b> {{ selectedUser?.brojLicneKarte }}</p>
        <p><b>Adresa: </b>{{ selectedUser?.adresa }}</p>
        <p><b> Datum iznajmljivanja: </b>{{ currentDate }}</p>
        <mat-divider></mat-divider>
        <p>
          Iznajmio/la instrument: {{ userObject?.firstname }}
          {{ userObject?.lastname }}
        </p>
        <p><b> Ime instrumenta: </b>{{ instrument?.Naziv }}</p>
        <p><b> Proizvođač: </b> {{ instrument?.Proizvodjac }}</p>
        <p><b> Inventarski broj: </b> {{ instrument?.invetarskiBroj }}</p>
        <p><b> Serijski broj: </b> {{ instrument?.serijskiBroj }}</p>
        <div style="display: flex; flex-flow: row wrap;" fxLayoutGap="10px">
          <div>
            <form class="example-form" style="width: 100% !important;">
              <mat-form-field
                class="example-full-width"
                style="width: 100% !important;"
              >
                <input
                  type="number"
                  #cenaIznajmljivanja
                  value="{{ instrument?.Cena }}"
                  matInput
                  placeholder="Cena iznajmljivanja(RSD): "
                />
              </mat-form-field>
            </form>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div style="display: flex; flex-flow: row wrap;" fxLayoutGap="500px">
          <div>
            <p>Za školu:</p>
            <p>
              <b>{{ userObject?.firstname }} {{ userObject?.lastname }} </b>
            </p>
            <p>_____________________________</p>
          </div>
          <div>
            <p>Za korisnika:</p>
            <p>
              <b>{{ selectedUser?.firstname }} {{ selectedUser?.lastname }} </b>
            </p>
            <p>_____________________________</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center;" *ngIf="selectedUser">
    <p style="color: red;" *ngIf="!selectedUser.adresa">
      Morate uneti adresu korisnika pre kreiranja reversa.
    </p>
  </div>
  <div style="text-align: center;" *ngIf="showReverse">
    <button
      mat-stroked-button
      style="margin: 2%;"
      (click)="updateInstrumentStatus()"
      *ngIf="selectedUser.adresa"
    >
      <mat-icon>create</mat-icon> Izdavanje
    </button>
  </div>
</div>
