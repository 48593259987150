import { InstrumentEditComponent } from "./../../instrument-edit/instrument-edit.component";
import { Component, OnInit, Input } from "@angular/core";
import { Instrument } from "../../instrument.model";
import { MatDialog } from "@angular/material";
import { InstrumentDetailComponent } from "../../instrument-detail/instrument-detail.component";
import { InstrumentIssuingComponent } from "../../instrument-issuing/instrument-issuing.component";
import { InstrumentsService } from "../../instruments.service";
import { AuthService } from "src/app/auth/auth.service";
import { CommentViewComponent } from "./comment-view/comment-view.component";
// import { MessageComponent } from 'src/app/reservation/message/message.component';

@Component({
  selector: "app-instrument-item",
  templateUrl: "./instrument-item.component.html",
  styleUrls: ["./instrument-item.component.css"],
})
export class InstrumentItemComponent implements OnInit {
  @Input() instrument: Instrument;
  currentSchoolKey: any = "";
  loggedUser: any;

  constructor(
    private dialog: MatDialog,
    private instrumentService: InstrumentsService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loggedUser = this.authService.logedUser;

    if (this.instrumentService.currentSchooKey === "1")
      this.currentSchoolKey = 1;
    else this.currentSchoolKey = this.instrumentService.currentSchooKey;
  }

  onOpenEditDialog(key, schoolKey) {
    this.dialog.open(InstrumentEditComponent, {
      disableClose: false,
      autoFocus: true,
      width: "700px",
      height: "700px",

      data: {
        instrumentKey: schoolKey + "/" + key,
      },
    });
  }

  onOpenInstrumentDetail(key, schoolKey) {
    this.dialog.open(InstrumentDetailComponent, {
      disableClose: false,
      autoFocus: true,
      height: "800px",
      width: "1200px",
      data: {
        instrumentKey: schoolKey + "/" + key,
      },
    });
  }

  onOpenInstrumentIssuing(key, schoolKey) {
    this.dialog.open(InstrumentIssuingComponent, {
      disableClose: false,
      autoFocus: true,
      width: "1000px",
      height: "700px",

      data: {
        instrumentKey: schoolKey + "/" + key,
      },
    });
  }
  onOpenCommentView(comment) {
    this.dialog.open(CommentViewComponent, {
      disableClose: false,
      autoFocus: true,
      width: "",
      height: "",

      data: {
        commentSection: comment,
      },
    });
  }

  /*onOpenMessageReservation() {
    let uid = this.authService.logedUserUID();

    this.dialog.open(MessageComponent, {
      disableClose: false,
      autoFocus: true,
      minWidth: '50%',
      width:'99%',
      height: '',

      data: {
        // commentSection: comment
        instrument: this.instrument,
        pathToChat: this.instrument.School.key + '/' + uid,
      }
    });
  }*/
}
