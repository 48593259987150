<div id="print-issuing-section" *ngIf="!data.issuingKey">
  <div style="text-align: center;">
    <!-- <h4> Muzička škola {{issue?.Instrument?.School?.Naziv}}</h4> -->
    <h4>UGOVOR</h4>
    <p>o izdavanju instrumenata muzičkih instrumenata škole</p>
    <br />
  </div>
  <div>
    <p>Ovim reversom se potvrđuje da je korisnik instrumentarija:</p>
    <p><b> Ime prezime:</b> {{ issue?.User }}</p>
    <p><b>Broj lične karte:</b> {{ issue?.brLicneKarte }}</p>
    <p><b>Adresa:</b> {{ issue?.adresa }}</p>
    <p>
      <b>Datum iznajmljivanja: </b> {{ issue?.IssuingDate }} u
      {{ issue?.IssuingTime }}
    </p>
    <mat-divider></mat-divider>
  </div>
  <div>
    <p>Iznajmio/la instrument:</p>
    <p><b>Ime instrumenta:</b> {{ issue?.Naziv }}</p>
    <p><b>Proizvođač:</b> {{ issue?.Proizvodjac }}</p>
    <p><b>Serijski broj:</b> {{ issue?.serijskiBroj }}</p>
    <p><b>Inventarski broj:</b> {{ issue?.invetarskiBroj }}</p>
    <p><b>Cena iznajmljivanja:</b> {{ issue?.Cena }}</p>
  </div>
  <mat-divider></mat-divider>
  <div style="display: flex; flex-flow: row wrap;" fxLayoutGap="300px">
    <div>
      <p>Za školu:</p>
      <p>
        <b>{{ issue?.UserWhoIssueTheInstrument }}</b>
      </p>
      <p>_____________________________</p>
    </div>
    <div>
      <p>Za korisnika:</p>
      <p>
        <b> {{ issue?.User }}</b>
      </p>
      <p>_____________________________</p>
    </div>
  </div>
  <div style="text-align: center;">
    <button
      [useExistingCss]="true"
      mat-stroked-button
      color="primary"
      printSectionId="print-issuing-section"
      ngxPrint
      style="margin: 2%;"
    >
      <mat-icon>print</mat-icon> Štampaj
    </button>
  </div>
</div>

<div id="print-issuing-section" *ngIf="data.issuingKey">
  <div style="text-align: center;">
    <h4>Muzička škola {{ issue?.Instrument?.School?.Naziv }}</h4>
    <h4>UGOVOR</h4>
    <p>o izdavanju instrumenata muzičkih instrumenata škole</p>
    <br />
  </div>
  <div>
    <p>Ovim reversom se potvrđuje da je korisnik instrumentarija:</p>
    <p><b> Ime prezime:</b> {{ issue?.User?.imePrezime }}</p>
    <p><b>Broj lične karte:</b> {{ issue?.User?.brLicneKarte }}</p>
    <p><b>Adresa:</b> {{ issue?.User?.adresa }}</p>
    <p>
      <b>Datum iznajmljivanja: </b> {{ issue?.Instrument?.IssuingDate }} u
      {{ issue?.Instrument?.IssuingTime }}
    </p>
    <mat-divider></mat-divider>
  </div>
  <div>
    <p>Iznajmio/la instrument:</p>
    <p><b>Ime instrumenta:</b> {{ issue?.Instrument?.Naziv }}</p>
    <p><b>Proizvođač:</b> {{ issue?.Instrument?.Proizvodjac }}</p>
    <p><b>Serijski broj:</b> {{ issue?.Instrument?.serijskiBroj }}</p>
    <p><b>Inventarski broj:</b> {{ issue?.Instrument?.invetarskiBroj }}</p>
    <p><b>Cena iznajmljivanja:</b> {{ issue?.Instrument?.Cena }}</p>
  </div>
  <mat-divider></mat-divider>
  <div>
    <div style="float: left;">
      <p>Za školu:</p>
      <p>
        <b>{{ issue?.Instrument?.UserWhoIssueTheInstrument?.Name }}</b>
      </p>
      <p>_____________________________</p>
    </div>
    <div style="float: right;">
      <p>Za korisnika:</p>
      <p>
        <b> {{ issue?.User?.imePrezime }}</b>
      </p>
      <p>_____________________________</p>
    </div>
  </div>
</div>
<div style="text-align: center;">
  <button
    [useExistingCss]="true"
    mat-stroked-button
    color="primary"
    printSectionId="print-issuing-section"
    ngxPrint
    style="margin: 2%;"
  >
    <mat-icon>print</mat-icon> Štampaj
  </button>
  <button
    mat-stroked-button
    color="primary"
    style="margin: 2%;"
    (click)="updateInstrumentStatus()"
    *ngIf="
      issue?.Instrument?.School?.key === userObject?.School.key &&
      issue?.Instrument?.Status === 'Zauzet'
    "
  >
    <mat-icon>assignment_turned_in</mat-icon> Razduži instrument
  </button>
</div>
