<div style="text-align: center;">
  <h4>Odaberite kolone za štampanje</h4>
  <mat-divider></mat-divider>

  <div fxLayout="row" style="margin-top: 2%;" fxLayoutGap="50px">
    <div fxLayout="column" fxLayoutGap="20px">
      <div *ngFor="let item of this.data.displayedColumns">
        <mat-label style="float: left;"
          *ngIf="item != 'serijskiBroj' && item !='Cena' && item != 'invetarskiBroj' && item != 'Proizvodjac'  && item != 'GodinaProizvodnje' && item != 'NacinNabavke'">
          {{item}}
        </mat-label>
        <ng-container *ngIf="item == 'serijskiBroj'">
          <mat-label style="float: left;">Serijski broj</mat-label>
        </ng-container>
        <ng-container *ngIf="item == 'invetarskiBroj'">
          <mat-label style="float: left;"> Inventarski broj</mat-label>
        </ng-container>
        <ng-container *ngIf="item == 'Proizvodjac'">
          <mat-label style="float: left;"> Proizvođač</mat-label>
        </ng-container>
        <ng-container *ngIf="item == 'GodinaProizvodnje'">
          <mat-label style="float: left;"> Godina</mat-label>
        </ng-container>
        <ng-container *ngIf="item == 'NacinNabavke'">
          <mat-label style="float: left;"> Način nabavke</mat-label>
        </ng-container>
        <ng-container *ngIf="item == 'Cena'">
          <mat-label style="float: left;"> Vrednost (RSD)</mat-label>
        </ng-container>
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div *ngFor="let item of this.data.displayedColumns">
        <mat-checkbox color="primary" (change)="checkBoxChange($event, item)" [checked]="true"></mat-checkbox>
      </div>
    </div>

  </div>
  <mat-divider style="margin-top: 2%;"></mat-divider>

  <button style="margin-top:20%;" color="primary" routerLink='/instrumentiStampa' (click)="closeDialog()"
    mat-stroked-button>Završite odabir</button>
</div>
