<button mat-stroked-button routerLink='/pregledInstrumenata'>
  <mat-icon>list</mat-icon> Detaljno
</button>
<button mat-stroked-button routerLink='/tabelaInstrumenti'>
  <mat-icon>table_chart</mat-icon> Tabelarno
</button>

<div class="mat-elevation-z14 table" style="overflow-x: scroll; margin:5%;">
    <mat-form-field class="search-form-field" style="margin-left: 1%">
        <input matInput [(ngModel)]="searchKey" placeholder="Pretraži" autocomplete="off" (keyup)="applyFilter()">
        <button mat-button *ngIf="searchKey" matSuffix mat-icon-button aria-label="Clear" (click)="searchKey='';
                     applyFilter()">
      <mat-icon>close</mat-icon>
    </button>
    </mat-form-field>
    <button mat-stroked-button (click)="addColumn()"> Dodaj kolonu </button>
    <button mat-stroked-button (click)="removeColumn()"> Obriši kolonu </button>
    <button mat-stroked-button (click)="onOpenPrintConfiguration()"> Pregled pre štampe </button>
    <div id="print-section">
        <table style="max-width: 100%; " mat-table [dataSource]="listData" class="table-responsive" class="mat-elevation-z8" matSort>
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <ng-container *ngIf="column != 'serijskiBroj' && column != 'invetarskiBroj' && column != 'Proizvodjac' && column != 'GodinaProizvodnje'
                                    && column != 'NacinNabavke' && column !='Cena'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> {{column}}</th>
                </ng-container>
                <ng-container *ngIf="column == 'serijskiBroj'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Serijski broj </th>
                </ng-container>
                <ng-container *ngIf="column == 'invetarskiBroj'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Inventarski broj</th>
                </ng-container>
                <ng-container *ngIf="column == 'Proizvodjac'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Proizvođač</th>
                </ng-container>
                <ng-container *ngIf="column == 'GodinaProizvodnje'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Godina</th>
                </ng-container>
                <ng-container *ngIf="column == 'NacinNabavke'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Način nabavke</th>
                </ng-container>
                <ng-container *ngIf="column == 'Cena'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Procenenjena vrednost (RSD)</th>
                </ng-container>
                <ng-container *ngIf="column == 'Izdaj'">
                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Izdaj</th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="element.Status === 'Za izdavanje'" mat-button (click)="onOpenInstrumentIssuing(element.$key, element.SchoolKey)">
                            <mat-icon>shopping_cart</mat-icon>
                        </button>
                        <button *ngIf="element.Status !== 'Za izdavanje'" mat-button disabled>
                            <mat-icon style="color: green;">done</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <td mat-cell *matCellDef="let element">
                    {{element[column]}}
                    <img *ngIf="column == 'Fotografija'" style="max-width: 50px" src="{{element.imageUrls[0]}}">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="onOpenInstrumentDetail(row, $event)" style="cursor: pointer;"></tr>
        </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5,10,25,100]" [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>