/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-view.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./comment-view.component";
import * as i3 from "@angular/material/dialog";
var styles_CommentViewComponent = [i0.styles];
var RenderType_CommentViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentViewComponent, data: {} });
export { RenderType_CommentViewComponent as RenderType_CommentViewComponent };
export function View_CommentViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comment; _ck(_v, 1, 0, currVal_0); }); }
export function View_CommentViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comment-view", [], null, null, null, View_CommentViewComponent_0, RenderType_CommentViewComponent)), i1.ɵdid(1, 114688, null, 0, i2.CommentViewComponent, [i3.MatDialog, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentViewComponentNgFactory = i1.ɵccf("app-comment-view", i2.CommentViewComponent, View_CommentViewComponent_Host_0, {}, {}, []);
export { CommentViewComponentNgFactory as CommentViewComponentNgFactory };
