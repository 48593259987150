<button *ngIf="columnsForPrinting.length>0" [useExistingCss]="true" mat-stroked-button color="primary" printSectionId="print-section" ngxPrint style="margin: 2%;">Štampaj</button>
<div style="padding: 5%;" id="print-section" *ngIf="columnsForPrinting.length>0">
    <h1 style="text-align: center;"> {{authService.logedUser?.School?.Naziv}} </h1>
    <table>
        <thead>
            <tr>
                <td>
                    <div class="header-space"></div>
                    <h4>Spisak instrumenata za odabrani kriterijum</h4>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div class="content">
                        <router-outlet></router-outlet>
                        <table>
                            <tr>
                                <th>Red. br.</th>
                                <ng-container *ngFor="let item of columnsForPrinting">
                                    <th *ngIf="item != 'serijskiBroj' && item != 'invetarskiBroj' && item !='Proizvodjac' && item != 'GodinaProizvodnje' && item != 'NacinNabavke'
                    && item != 'Cena'">
                                        {{item}}</th>
                                    <th *ngIf="item == 'serijskiBroj'">Serijski broj</th>
                                    <th *ngIf="item == 'invetarskiBroj'">Inventarski broj</th>
                                    <th *ngIf="item == 'Proizvodjac'">Proizvođač</th>
                                    <th *ngIf="item == 'GodinaProizvodnje'">Godina</th>
                                    <th *ngIf="item == 'NacinNabavke'">Način nabavke</th>
                                    <th *ngIf="item == 'Cena'">Procenenjena vrednost (RSD)</th>
                                </ng-container>
                            </tr>
                            <tr *ngFor="let instrumentElement of dataForPrinting; let i = index;">
                                <td>{{i+1}}</td>
                                <td *ngIf="nazivExists">{{instrumentElement.Naziv}}</td>
                                <td *ngIf="invetarskiBrojExists">{{instrumentElement.invetarskiBroj}}</td>
                                <td *ngIf="serijskiBrojExists">{{instrumentElement.serijskiBroj}}</td>
                                <td *ngIf="TipExists">{{instrumentElement.Tip}}</td>
                                <td *ngIf="ProizvodjacExists">{{instrumentElement.Proizvodjac}}</td>
                                <td *ngIf="GodinaProizvodnjeExists">{{instrumentElement.GodinaProizvodnje}}</td>
                                <td *ngIf="NacinNabavkeExists">{{instrumentElement.NacinNabavke}}</td>
                                <td *ngIf="CenaExists">{{instrumentElement.Cena}}</td>
                                <td *ngIf="StatusExists">{{instrumentElement.Status}}</td>
                                <td *ngIf="ProstorijaExists">{{instrumentElement.Prostorija}}</td>
                                <td *ngIf="KomentarExists">{{instrumentElement.Komentar}}</td>
                            </tr>
                        </table>

                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td>
                    <div class="footer-space"></div>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
<div *ngIf="columnsForPrinting.length==0">
    <h3>Nema ništa za štampanje.</h3>
</div>