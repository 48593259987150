<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar>
            <img routerLink='/pregledInstrumenata' src="assets/images/galis_250x196.jpg"
                style="max-height: 50px; margin: 0 auto;" alt="logo" />
        </mat-toolbar>

        <mat-nav-list>
            <a mat-list-item *ngIf="!user" (click)="onOpenLoginDialog()">
                <mat-icon>input</mat-icon> Prijava
            </a>
            <div mat-list-item>

                <a *ngIf="authService.logedUser?.role === 'SuperAdmin'" routerLink='/korisnici' mat-list-item href="#">
                    <mat-icon>supervised_user_circle</mat-icon> Korisnici
                </a>
                <a *ngIf="authService.logedUser?.role === 'AdminSkole' && user && authService.logedUser?.AccountEnabled"
                    routerLink='/korisnici' mat-list-item href="#">
                    <mat-icon>supervised_user_circle</mat-icon> Korisnici
                </a>
            </div>

            <mat-accordion mat-list-item *ngIf="user && authService.logedUser?.AccountEnabled">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon>library_music</mat-icon>
                        </mat-panel-title>
                        <mat-panel-description>
                            Instrumenti
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <a routerLink='/unosInstrumenta' mat-list-item href="#"
                        *ngIf="authService.logedUser?.role === 'AdminSkole'  || authService.logedUser?.role === 'SuperAdmin'">
                        <mat-icon>library_add</mat-icon> Unos
                    </a>
                    <a routerLink='/pregledInstrumenata' mat-list-item href="#">
                        <mat-icon>library_music</mat-icon> Pregled
                    </a>

                    <a routerLink='/izvestaji' mat-list-item
                        *ngIf="authService.logedUser?.role === 'AdminSkole'  || authService.logedUser?.role === 'SuperAdmin'">
                        <mat-icon>assignment</mat-icon> Izveštaji
                    </a>
                </mat-expansion-panel>
            </mat-accordion>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style>
        <div id="destination">
        </div>
        <mat-toolbar color="primary">
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <div style="display: flex; flex-flow:row wrap;">
                <span>Instrumentarijum </span>

                <button *ngIf="user" mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>account_circle</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <p style="text-align: center; color: green; font-size: smaller;">
                        {{authService.logedUser?.firstname}} {{authService.logedUser?.lastname}}
                    </p>
                    <mat-divider></mat-divider>
                    <button *ngIf="user" mat-menu-item routerLink='/userProfile'>
                        <mat-icon>dashboard</mat-icon> Korisnički profil
                    </button>
                    <!-- <button routerLink='/rezervacije' style="font-size: smaller;" mat-menu-item 
                        *ngIf="authService.logedUser?.role === 'AdminSkole'  || authService.logedUser?.role === 'SuperAdmin'">
                        <mat-icon>inbox</mat-icon> Pošta na nivou škole
                    </button>
                    <button routerLink='/pošta' mat-menu-item  style="font-size: smaller;"
                        *ngIf="authService.logedUser?.role === 'AdminSkole'  || authService.logedUser?.role === 'SuperAdmin'">
                        <mat-icon style="color: blue;">inbox</mat-icon> Personalna pošta
                    </button> -->
                    <button *ngIf="authService.logedUser?.role" mat-menu-item style="color:red;" (click)="logout()">
                        <mat-icon>call_received</mat-icon>Odjava
                    </button>
                </mat-menu>

            </div>
        </mat-toolbar>
        <router-outlet></router-outlet>
        <mat-toolbar>
            <div class="footer">
                <p style="font-size: small;"> <a href="https://galis.rs" target="_blank"
                        style="text-decoration:none; color: white;"> © {{currentYear}} Galilej Informacione Tehnologije
                    </a></p>
            </div>
        </mat-toolbar>
    </mat-sidenav-content>
</mat-sidenav-container>