import { InstrumentsService } from './../instruments.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { SharedClass } from 'src/app/shared/shared-functions';

export class Instrument {
  Naziv: string;
}

export class InstrumentMaker {
  Naziv: string;
}

export class InstrumentStatus {
  Naziv: string;
}
export class InstrumentType {
  Naziv: string;
}

export class InstrumentPurchaseType {
  Naziv: string;
}

@Component({
  selector: 'app-instrument-input',
  templateUrl: './instrument-input.component.html',
  styleUrls: ['./instrument-input.component.css']
})
export class InstrumentInputComponent implements OnInit {

  filteredOptionsNaziv: Observable<string[]>;
  filteredOptionsTip: Observable<string[]>;
  filteredOptionsProizvodjac: Observable<string[]>;
  filteredOptionsNacinNabavke: Observable<string[]>;


  optionsNaziv: any = [];
  optionsTip: any = [];
  optionsProizvodjac: any = [];
  optionsNacinNabavke: any = [];

  instruments: Instrument[];
  instrumentTypes: InstrumentType[];
  instrumentMakers: InstrumentMaker[];
  instrumentPurchaseTypes: InstrumentPurchaseType[];
  instrumentStatuses: InstrumentStatus[];

  myControlNaziv = this.instrumentService.form.controls.Naziv;
  myControlTip = this.instrumentService.form.controls.Tip;
  myControlProizvodjac = this.instrumentService.form.controls.Proizvodjac;
  myControlNacinNabavke = this.instrumentService.form.controls.NacinNabavke;
  yearsOfProduction: number[] = []
  submitted: boolean;
  inputValidators: boolean[] = []

  @ViewChild('Naziv', { static: true }) inputNaziv: any;
  @ViewChild('Tip', { static: true }) inputTip: any;
  @ViewChild('Proizvodjac', { static: true }) inputProizvodjac: any;
  @ViewChild('NacinNabavke', { static: true }) inputNacinNabavke: any;

  userObject: User = {};
  user: firebase.User;
  files: any;

  public selectedStatus = 'Aktivan';

  constructor(public instrumentService: InstrumentsService,
    private dialog: MatDialog,
    public sharedClass: SharedClass,
  ) {
    let j = 0;
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      this.yearsOfProduction[j++] = i;
    }
  }

  ngOnInit() {
    this.sharedClass.oneImagedownloadUrl = [];
    this.instrumentService.form.reset();

    this.loadInstruments();
    this.loadInstrumentTypes();
    this.loadInstrumentMakers();
    this.loadInstrumentPurchaseTypes();
    this.loadInstrumentStatuses();

    this.filteredOptionsNaziv = this.myControlNaziv.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNaziv(value))
      );
    this.filteredOptionsTip = this.myControlTip.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterTip(value))
      );
    this.filteredOptionsProizvodjac = this.myControlProizvodjac.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterProizvodjac(value))
      );
    this.filteredOptionsNacinNabavke = this.myControlNacinNabavke.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNacinNabavke(value))
      );
  }

  private _filterNaziv(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsNaziv.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterTip(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsTip.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterProizvodjac(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsProizvodjac.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterNacinNabavke(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsNacinNabavke.filter(option => option.toLowerCase().includes(filterValue));
  }

  onSubmit() {

    this.submitted = true;


    let instrumentAutoCompleteInputs: {
      Naziv: string,
      Tip: string,
      Proizvodjac: string,
      NacinNabavke: string
    } = {
      Naziv: this.inputNaziv.nativeElement.value,
      Tip: this.inputTip.nativeElement.value,
      Proizvodjac: this.inputProizvodjac.nativeElement.value,
      NacinNabavke: this.inputNacinNabavke.nativeElement.value,
    }

    if (instrumentAutoCompleteInputs.Naziv != '' && instrumentAutoCompleteInputs.Tip != '' &&
      instrumentAutoCompleteInputs.Proizvodjac != '' && this.instrumentService.form.get('invetarskiBroj').value != ''
      && this.instrumentService.form.get('GodinaProizvodnje').value != '') {

      if (!this.checkIfNameExistsAutoComplete(instrumentAutoCompleteInputs.Naziv))
        this.instrumentService.insertInstrument(instrumentAutoCompleteInputs.Naziv);

      if (!this.checkIfInstrumentTypeExistsAutoComplete(instrumentAutoCompleteInputs.Tip))
        this.instrumentService.insertInstrumentType(instrumentAutoCompleteInputs.Tip);

      if (!this.checkIfMakerNameExistsAutoComplete(instrumentAutoCompleteInputs.Proizvodjac))
        this.instrumentService.insertInstrumentMaker(instrumentAutoCompleteInputs.Proizvodjac);

      if (!this.checkIfPurchaseTypeExistsAutoComplete(instrumentAutoCompleteInputs.NacinNabavke))
        this.instrumentService.insertInstrumentPurchaseType(instrumentAutoCompleteInputs.NacinNabavke);

      this.instrumentService.insertInstrumentSample(this.instrumentService.form.value, this.sharedClass.oneImagedownloadUrl, instrumentAutoCompleteInputs);

      //reseting all input fields
      this.sharedClass.oneImagedownloadUrl = [];
      this.instrumentService.form.reset();
      this.ngOnInit();
      for (let i = 0; i < this.inputValidators.length; i++) this.inputValidators[i] = false;


    }

    //reseting values in sharedClass for next upload
    this.sharedClass.progressValue = 0;
    this.sharedClass.imageFiles = [];
    this.sharedClass.downloadURLs = []
    this.sharedClass.fileCounter = 0;
    this.submitted = false;

  }



  checkIfNameExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instruments.length; i++) {
      if (naziv == this.instruments[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfInstrumentTypeExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentTypes.length; i++) {
      if (naziv == this.instrumentTypes[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfMakerNameExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentMakers.length; i++) {
      if (naziv == this.instrumentMakers[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfPurchaseTypeExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentPurchaseTypes.length; i++) {
      if (naziv == this.instrumentPurchaseTypes[i].Naziv)
        return true;
    }
    return false;
  }

  loadInstruments() {
    this.instrumentService.getInstruments().subscribe(
      list => {
        this.instruments = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }
  loadInstrumentTypes() {
    this.instrumentService.getInstrumentTypes().subscribe(
      list => {
        this.instrumentTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentMakers() {
    this.instrumentService.getInstrumentMakers().subscribe(
      list => {
        this.instrumentMakers = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentPurchaseTypes() {
    this.instrumentService.getInstrumentPurchaseType().subscribe(
      list => {
        this.instrumentPurchaseTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentStatuses() {
    this.instrumentService.getInstrumentStatuses().subscribe(
      list => {
        this.instrumentStatuses = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  takeInstruments() {
    this.optionsNaziv = []
    for (let i = 0; i < this.instruments.length; i++) {
      this.optionsNaziv[i] = this.instruments[i].Naziv;
    }
  }
  takeInstrumentsType() {
    this.optionsTip = []
    for (let i = 0; i < this.instrumentTypes.length; i++) {
      this.optionsTip[i] = this.instrumentTypes[i].Naziv;
    }
  }
  takeInstrumentsMaker() {
    this.optionsProizvodjac = []
    for (let i = 0; i < this.instrumentMakers.length; i++) {
      this.optionsProizvodjac[i] = this.instrumentMakers[i].Naziv;
    }
  }

  takeInstrumentPurchaseType() {
    this.optionsNacinNabavke = []
    for (let i = 0; i < this.instrumentPurchaseTypes.length; i++) {
      this.optionsNacinNabavke[i] = this.instrumentPurchaseTypes[i].Naziv;
    }
  }


  checkNaziv(event) {
    if (event.target.value != '') {
      this.inputValidators[0] = true;
    }
    else {
      this.inputValidators[0] = false;
    }
  }

  checkTip(event) {
    if (event.target.value != '') {
      this.inputValidators[1] = true;
    }
    else {
      this.inputValidators[1] = false;
    }
  }
  checkProizvodjac(event) {
    if (event.target.value != '') {
      this.inputValidators[2] = true;
    }
    else {
      this.inputValidators[2] = false;
    }
  }
  checkNacinNabavke(event) {
    if (event.target.value != '') {
      this.inputValidators[3] = true;
    }
    else {
      this.inputValidators[3] = false;
    }
  }

  uploadImages(event) {
    this.sharedClass.uploadedBoolean = false;
    this.sharedClass.uploadImages(event, 'instruments');
  }

  uploadImage(event) {
    this.sharedClass.uploadImage(event, 'instruments');
  }

  onDeletePhoto(url) {
    this.sharedClass.deletePhotoFromFirebaseStorage(url)
  }

  disableButton(event) {
    event.target.disabled = true;
  }
}