<div style="margin: 5%;">
  <div style="display: flex;">
    <div style="margin-top: 1%;">
      <button mat-stroked-button (click)="onOpenUserRegistrationDialog()">
        Novi korisnik
      </button>
    </div>
    <mat-form-field
      class="search-form-field"
      style="width: 100%; margin-left: 2%;"
    >
      <input
        matInput
        [(ngModel)]="searchKey"
        placeholder="Pretraži"
        autocomplete="off"
        (keyup)="applyFilter()"
      />
      <button
        mat-button
        *ngIf="searchKey"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchKey = ''; applyFilter()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-table class="mat-elevation-z14 table" [dataSource]="listData" matSort>
    <ng-container matColumnDef="Aktivan">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Aktivan
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.AccountEnabled" style="color: greenyellow;"
          >done_outline</mat-icon
        >
        <mat-icon *ngIf="!element.AccountEnabled" style="color: red;"
          >cancel</mat-icon
        >
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ImePrezime">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Ime i prezime
      </mat-header-cell>
      <mat-cell *matCellDef="let element"
        >{{ element.firstname }} {{ element.lastname }}</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="Rola">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Privilegija
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.role }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="Opcije">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Prikaži / deaktiviraj
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button
          mat-stroked-button
          (click)="onOpenUserDialog(element.$key)"
          style="margin-right: 2%;"
        >
          <mat-icon>search</mat-icon>
        </button>
        <button
          mat-stroked-button
          color="accent"
          (click)="onDisableUser(element.$key)"
          *ngIf="element.AccountEnabled"
        >
          <mat-icon> arrow_downward </mat-icon>
        </button>
        <button
          mat-stroked-button
          style="color: green;"
          (click)="onEnableUser(element.$key)"
          *ngIf="!element.AccountEnabled"
        >
          <mat-icon> arrow_upward </mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="5"
    showFirstLastButtons
  ></mat-paginator>
</div>
