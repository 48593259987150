<div style="text-align: center;">
    <h4>Odaberite kolone za štampanje aaa</h4>
    <mat-divider></mat-divider>
    <div fxLayout="row" style="margin-top: 2%;" fxLayoutGap="50px">
        <div fxLayout="column" fxLayoutGap="20px">
            <div>
                <ng-container>
                    <mat-label style="float: left;">Index</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Korisnik</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Email</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Naziv</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Datum izdavanja</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Vrema izdavanja</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Cena</mat-label>
                </ng-container>
            </div>
            <div>
                <ng-container>
                    <mat-label style="float: left;">Status</mat-label>
                </ng-container>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="20px">
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 0)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 1)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 2)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 3)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 4)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 5)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 6)" [checked]="true"></mat-checkbox>
            </div>
            <div>
                <mat-checkbox color="primary" (change)="checkBoxChange($event, 7)" [checked]="true"></mat-checkbox>
            </div>
        </div>
    </div>
    <mat-divider style="margin-top: 2%;"></mat-divider>

    <button style="margin-top:20%;" color="primary" routerLink='/izvestajiStampa' (click)="closeDialog()" mat-stroked-button>Završite odabir</button>
</div>