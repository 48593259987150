import {
  InstrumentMaker,
  InstrumentType,
  InstrumentPurchaseType,
  InstrumentStatus,
} from "./../instrument-edit/instrument-edit.component";
import { InstrumentsService } from "./../instruments.service";
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { Instrument } from "./../instrument.model";
import { AuthService } from "src/app/auth/auth.service";
import { MatDialog, MatCheckboxChange } from "@angular/material";

@Component({
  selector: "app-instrument-list",
  templateUrl: "./instrument-list.component.html",
  styleUrls: ["./instrument-list.component.css"],
})
export class InstrumentListComponent implements OnInit {
  instruments: any[] = [];
  filteredInstruments: any[] = [];

  instrumentsName: Instrument[] = [];
  instrumentMakers: InstrumentMaker[] = [];
  instrumentTypes: InstrumentType[] = [];
  instrumentPurchaseTypes: InstrumentPurchaseType[] = [];
  instrumentStatuses: InstrumentStatus[] = [];

  instrumentMakerChecked = true;
  instrumentTypeChecked = true;
  instrumentChecked = true;
  instrumentPurchaseChecked = true;
  instrumentStatusChecked = true;
  @ViewChild("searchInput", { static: true }) searchInput: any;
  @ViewChildren("myCheckbox") private myCheckboxes: any;

  selectedNazivProizvodjaca: string = "";
  selectedTip: string = "";
  selectedNaziv: string = "";
  selectedStatus: string = "";

  musicalSchools: any[] = [];

  skole: any[] = [];
  loggedUser: any = {};

  isLoading: boolean = true;

  selectedSve = "Sve";

  constructor(
    public instrumentService: InstrumentsService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadInstrumentMakers();
    this.loadInstrumentTypes();
    this.loadInstrumentPurchaseTypes();
    this.loadInstrumentStatuses();
    this.loadMusicalSchool();
    this.loadInstrumentsNames();
    this.loggedUser = this.authService.logedUser;
    this.dialog.closeAll();
    this.loadInstrumentsFromEverySchool();
  }

  loadMusicalSchool() {
    this.instrumentService.getAllInstrumentSamples().subscribe((list) => {
      this.musicalSchools = list.map((item) => {
        return {
          $key: item.key,
          ...(item.payload.val() as any),
        };
      });

      let i = 0;
      this.skole = [];
      this.musicalSchools.forEach((element) => {
        this.instrumentService
          .getMusicalSchoolByKey(element.$key)
          .subscribe((item) => {
            this.skole.push({ $key: element.$key, Naziv: item.Naziv });
            if (this.musicalSchools.length - 1 === i++) {
              this.loadToCurrentSchool();
            }
          });
      });
    });
  }

  getInstrumentsBySelectedSchool(nameOfSelectedSchool?) {
    this.isLoading = true;

    let schoolKey = this.skole.filter(
      (item) => item.Naziv === nameOfSelectedSchool
    )[0].$key;
    if (schoolKey) {
      this.instrumentService
        .getInstrumentsBySelectedSchool(schoolKey)
        .subscribe((list) => {
          this.filteredInstruments = list.map((item) => {
            return {
              $key: item.key,
              ...(item.payload.val() as any),
            };
          });
          this.instruments = this.filteredInstruments;
          this.filteredInstruments = this.instruments;
          this.isLoading = false;
        });
    }
  }

  loadInstrumentsFromEverySchool() {
    this.instruments = [];
    this.filteredInstruments = [];

    this.skole.forEach((element) => {
      this.instrumentService
        .getInstrumentsBySelectedSchool(element.$key)
        .subscribe((list) => {
          this.filteredInstruments = list.map((item) => {
            return {
              $key: item.key,
              ...(item.payload.val() as any),
            };
          });
          this.instruments = this.instruments.concat(this.filteredInstruments);
          this.filteredInstruments = this.instruments;
          this.isLoading = false;
        });
    });
  }

  noElements: boolean = false;

  async loadToCurrentSchool() {
    this.instruments = [];
    this.filteredInstruments = [];

    if (this.skole.length) {
      this.isLoading = false;

      let loggedSchool = this.authService.logedUser.School.Naziv;
      let schoolKey = this.skole.filter(
        (item) => item.Naziv === loggedSchool
      )[0].$key;

      this.instrumentService.currentSchooKey = schoolKey;

      this.instrumentService
        .getInstrumentsBySelectedSchool(schoolKey)
        .subscribe((list) => {
          this.filteredInstruments = list.map((item) => {
            return {
              $key: item.key,
              ...(item.payload.val() as any),
            };
          });
          this.instruments = this.filteredInstruments;
          this.filteredInstruments = this.instruments;
          this.isLoading = false;
          this.loadInstrumentsFromEverySchool();
        });

      //reseting mat option
      this.selectedNazivProizvodjaca = "";
      this.selectedStatus = "";
      this.selectedTip = "";
      this.selectedNaziv = "";
    }
  }

  resetingCheckBoxes() {
    this.myCheckboxes._results.forEach((element) => {
      element._checked = false;
    });
    this.nazivProizvodjacaArrayForSearching = [];
  }

  loadInstrumentsNames() {
    this.instrumentService.getInstruments().subscribe((list) => {
      this.instrumentsName = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  loadInstrumentMakers() {
    this.instrumentService.getInstrumentMakers().subscribe((list) => {
      this.instrumentMakers = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  loadInstrumentTypes() {
    this.instrumentService.getInstrumentTypes().subscribe((list) => {
      this.instrumentTypes = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  loadInstrumentPurchaseTypes() {
    this.instrumentService.getInstrumentPurchaseType().subscribe((list) => {
      this.instrumentPurchaseTypes = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  loadInstrumentStatuses() {
    this.instrumentService.getInstrumentStatuses().subscribe((list) => {
      this.instrumentStatuses = list.map((item) => {
        return {
          $key: item.key,
          ...item.payload.val(),
        };
      });
    });
  }

  searchInstruments() {
    let searchInput = this.searchInput.nativeElement.value.toLowerCase();

    this.filteredInstruments = this.instruments.filter((item: any) => {
      return (
        item.Naziv.toLowerCase().includes(searchInput) ||
        item.Status.toLowerCase().includes(searchInput) ||
        item.serijskiBroj.toLowerCase().includes(searchInput) ||
        item.invetarskiBroj.toLowerCase().includes(searchInput) ||
        item.GodinaProizvodnje.toLowerCase().includes(searchInput) ||
        item.NacinNabavke.toLowerCase().includes(searchInput) ||
        item.Proizvodjac.toLowerCase().toLowerCase().includes(searchInput) ||
        item.Tip.toLowerCase().includes(searchInput) ||
        item.Cena.toLowerCase().includes(searchInput) ||
        item.School.Naziv.toLowerCase().includes(searchInput) ||
        // || item.Prostorija.toLowerCase().includes(searchInput)
        // || item.Polica.toLowerCase().includes(searchInput)
        item.Komentar.toLowerCase().includes(searchInput)
      );
    });
  }

  nazivProizvodjacaArrayForSearching: string[] = [];

  takeElement(element) {
    if (this.nazivProizvodjacaArrayForSearching.indexOf(element) === -1) {
      this.nazivProizvodjacaArrayForSearching.push(element);
    } else {
      let index: number = this.nazivProizvodjacaArrayForSearching.indexOf(
        element
      );
      this.nazivProizvodjacaArrayForSearching.splice(index, 1);
    }
  }

  onSearchAfterSelectedParameters() {
    this.filteredInstruments = this.instruments;

    let temp: any[] = [];

    if (this.nazivProizvodjacaArrayForSearching.length > 0) {
      this.filteredInstruments.forEach((element) => {
        this.nazivProizvodjacaArrayForSearching.forEach((selectedItem) => {
          if (selectedItem === element.Proizvodjac) temp.push(element);
        });
      });

      this.filteredInstruments = temp;
    }

    if (this.selectedNazivProizvodjaca) {
      this.filteredInstruments = this.filteredInstruments.filter(
        (item: any) => {
          return item.Proizvodjac === this.selectedNazivProizvodjaca;
        }
      );
    }

    if (this.selectedTip) {
      this.filteredInstruments = this.filteredInstruments.filter(
        (item: any) => {
          return item.Tip === this.selectedTip;
        }
      );
    }

    if (this.selectedNaziv) {
      this.filteredInstruments = this.filteredInstruments.filter(
        (item: any) => {
          return item.Naziv === this.selectedNaziv;
        }
      );
    }

    if (this.selectedStatus) {
      this.filteredInstruments = this.filteredInstruments.filter(
        (item: any) => {
          return item.Status === this.selectedStatus;
        }
      );
    }
  }
}
