import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { InstrumentsService } from '../../instruments.service';

@Component({
  selector: 'app-printreport-configuration',
  templateUrl: './printreport-configuration.component.html',
  styleUrls: ['./printreport-configuration.component.css']
})
export class PrintreportConfigurationComponent implements OnInit {

  boolReportColumns: boolean[] = [true, true, true, true, true, true, true, true];

  constructor(private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private instrumentService: InstrumentsService) { }

  ngOnInit() {
    this.instrumentService.columnsForPrintingReport = this.boolReportColumns;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkBoxChange(event, item) {

    event.checked ? this.boolReportColumns[item] = true : this.boolReportColumns[item] = false;;

    this.instrumentService.columnsForPrintingReport = this.boolReportColumns;
  }

}
