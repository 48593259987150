import * as tslib_1 from "tslib";
import { OnInit, } from "@angular/core";
import { MatDialog } from "@angular/material";
export class InstrumentListComponent {
    constructor(instrumentService, authService, dialog) {
        this.instrumentService = instrumentService;
        this.authService = authService;
        this.dialog = dialog;
        this.instruments = [];
        this.filteredInstruments = [];
        this.instrumentsName = [];
        this.instrumentMakers = [];
        this.instrumentTypes = [];
        this.instrumentPurchaseTypes = [];
        this.instrumentStatuses = [];
        this.instrumentMakerChecked = true;
        this.instrumentTypeChecked = true;
        this.instrumentChecked = true;
        this.instrumentPurchaseChecked = true;
        this.instrumentStatusChecked = true;
        this.selectedNazivProizvodjaca = "";
        this.selectedTip = "";
        this.selectedNaziv = "";
        this.selectedStatus = "";
        this.musicalSchools = [];
        this.skole = [];
        this.loggedUser = {};
        this.isLoading = true;
        this.selectedSve = "Sve";
        this.noElements = false;
        this.nazivProizvodjacaArrayForSearching = [];
    }
    ngOnInit() {
        this.loadInstrumentMakers();
        this.loadInstrumentTypes();
        this.loadInstrumentPurchaseTypes();
        this.loadInstrumentStatuses();
        this.loadMusicalSchool();
        this.loadInstrumentsNames();
        this.loggedUser = this.authService.logedUser;
        this.dialog.closeAll();
        this.loadInstrumentsFromEverySchool();
    }
    loadMusicalSchool() {
        this.instrumentService.getAllInstrumentSamples().subscribe((list) => {
            this.musicalSchools = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
            let i = 0;
            this.skole = [];
            this.musicalSchools.forEach((element) => {
                this.instrumentService
                    .getMusicalSchoolByKey(element.$key)
                    .subscribe((item) => {
                    this.skole.push({ $key: element.$key, Naziv: item.Naziv });
                    if (this.musicalSchools.length - 1 === i++) {
                        this.loadToCurrentSchool();
                    }
                });
            });
        });
    }
    getInstrumentsBySelectedSchool(nameOfSelectedSchool) {
        this.isLoading = true;
        let schoolKey = this.skole.filter((item) => item.Naziv === nameOfSelectedSchool)[0].$key;
        if (schoolKey) {
            this.instrumentService
                .getInstrumentsBySelectedSchool(schoolKey)
                .subscribe((list) => {
                this.filteredInstruments = list.map((item) => {
                    return Object.assign({ $key: item.key }, item.payload.val());
                });
                this.instruments = this.filteredInstruments;
                this.filteredInstruments = this.instruments;
                this.isLoading = false;
            });
        }
    }
    loadInstrumentsFromEverySchool() {
        this.instruments = [];
        this.filteredInstruments = [];
        this.skole.forEach((element) => {
            this.instrumentService
                .getInstrumentsBySelectedSchool(element.$key)
                .subscribe((list) => {
                this.filteredInstruments = list.map((item) => {
                    return Object.assign({ $key: item.key }, item.payload.val());
                });
                this.instruments = this.instruments.concat(this.filteredInstruments);
                this.filteredInstruments = this.instruments;
                this.isLoading = false;
            });
        });
    }
    loadToCurrentSchool() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.instruments = [];
            this.filteredInstruments = [];
            if (this.skole.length) {
                this.isLoading = false;
                let loggedSchool = this.authService.logedUser.School.Naziv;
                let schoolKey = this.skole.filter((item) => item.Naziv === loggedSchool)[0].$key;
                this.instrumentService.currentSchooKey = schoolKey;
                this.instrumentService
                    .getInstrumentsBySelectedSchool(schoolKey)
                    .subscribe((list) => {
                    this.filteredInstruments = list.map((item) => {
                        return Object.assign({ $key: item.key }, item.payload.val());
                    });
                    this.instruments = this.filteredInstruments;
                    this.filteredInstruments = this.instruments;
                    this.isLoading = false;
                    this.loadInstrumentsFromEverySchool();
                });
                //reseting mat option
                this.selectedNazivProizvodjaca = "";
                this.selectedStatus = "";
                this.selectedTip = "";
                this.selectedNaziv = "";
            }
        });
    }
    resetingCheckBoxes() {
        this.myCheckboxes._results.forEach((element) => {
            element._checked = false;
        });
        this.nazivProizvodjacaArrayForSearching = [];
    }
    loadInstrumentsNames() {
        this.instrumentService.getInstruments().subscribe((list) => {
            this.instrumentsName = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
        });
    }
    loadInstrumentMakers() {
        this.instrumentService.getInstrumentMakers().subscribe((list) => {
            this.instrumentMakers = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
        });
    }
    loadInstrumentTypes() {
        this.instrumentService.getInstrumentTypes().subscribe((list) => {
            this.instrumentTypes = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
        });
    }
    loadInstrumentPurchaseTypes() {
        this.instrumentService.getInstrumentPurchaseType().subscribe((list) => {
            this.instrumentPurchaseTypes = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
        });
    }
    loadInstrumentStatuses() {
        this.instrumentService.getInstrumentStatuses().subscribe((list) => {
            this.instrumentStatuses = list.map((item) => {
                return Object.assign({ $key: item.key }, item.payload.val());
            });
        });
    }
    searchInstruments() {
        let searchInput = this.searchInput.nativeElement.value.toLowerCase();
        this.filteredInstruments = this.instruments.filter((item) => {
            return (item.Naziv.toLowerCase().includes(searchInput) ||
                item.Status.toLowerCase().includes(searchInput) ||
                item.serijskiBroj.toLowerCase().includes(searchInput) ||
                item.invetarskiBroj.toLowerCase().includes(searchInput) ||
                item.GodinaProizvodnje.toLowerCase().includes(searchInput) ||
                item.NacinNabavke.toLowerCase().includes(searchInput) ||
                item.Proizvodjac.toLowerCase().toLowerCase().includes(searchInput) ||
                item.Tip.toLowerCase().includes(searchInput) ||
                item.Cena.toLowerCase().includes(searchInput) ||
                item.School.Naziv.toLowerCase().includes(searchInput) ||
                // || item.Prostorija.toLowerCase().includes(searchInput)
                // || item.Polica.toLowerCase().includes(searchInput)
                item.Komentar.toLowerCase().includes(searchInput));
        });
    }
    takeElement(element) {
        if (this.nazivProizvodjacaArrayForSearching.indexOf(element) === -1) {
            this.nazivProizvodjacaArrayForSearching.push(element);
        }
        else {
            let index = this.nazivProizvodjacaArrayForSearching.indexOf(element);
            this.nazivProizvodjacaArrayForSearching.splice(index, 1);
        }
    }
    onSearchAfterSelectedParameters() {
        this.filteredInstruments = this.instruments;
        let temp = [];
        if (this.nazivProizvodjacaArrayForSearching.length > 0) {
            this.filteredInstruments.forEach((element) => {
                this.nazivProizvodjacaArrayForSearching.forEach((selectedItem) => {
                    if (selectedItem === element.Proizvodjac)
                        temp.push(element);
                });
            });
            this.filteredInstruments = temp;
        }
        if (this.selectedNazivProizvodjaca) {
            this.filteredInstruments = this.filteredInstruments.filter((item) => {
                return item.Proizvodjac === this.selectedNazivProizvodjaca;
            });
        }
        if (this.selectedTip) {
            this.filteredInstruments = this.filteredInstruments.filter((item) => {
                return item.Tip === this.selectedTip;
            });
        }
        if (this.selectedNaziv) {
            this.filteredInstruments = this.filteredInstruments.filter((item) => {
                return item.Naziv === this.selectedNaziv;
            });
        }
        if (this.selectedStatus) {
            this.filteredInstruments = this.filteredInstruments.filter((item) => {
                return item.Status === this.selectedStatus;
            });
        }
    }
}
