import { Instrument } from "./instrument.model";
import { SharedClass } from "src/app/shared/shared-functions";
import { Injectable, ɵConsole } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "angularfire2/database";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class InstrumentsService {
  instrumentList: AngularFireList<any>;
  instrumentTypeList: AngularFireList<any>;
  instrumentMakerList: AngularFireList<any>;
  instrumentPurchaseTypeList: AngularFireList<any>;
  instrumentStatusList: AngularFireList<any>;
  instrumentSamplesList: AngularFireList<any>;
  instrumentIssuingList: AngularFireList<any>;

  uploaded: boolean;
  instrumentObject: AngularFireObject<Instrument>;
  musicalSchool: AngularFireObject<any>;

  //for instruments table
  columnsForPrinting: any = [];
  dataFromTableForPrinting: any;
  currentSchooKey: string = "";

  //for report table
  dataForPrintingReport: any;
  columnsForPrintingReport: any[] = [];

  userObject: User = {};
  user: firebase.User;

  constructor(
    private firebase: AngularFireDatabase,
    private authService: AuthService,
    private sharedClass: SharedClass,
    public db: AngularFireDatabase
  ) {
    this.instrumentList = firebase.list("instruments");
    this.instrumentTypeList = firebase.list("instrumentTypes");
    this.instrumentMakerList = firebase.list("instrumentMakers");
    this.instrumentPurchaseTypeList = firebase.list("instrumentPurchaseType");
    this.instrumentStatusList = firebase.list("instrumentStatuses");
    this.instrumentSamplesList = firebase.list("instrumentSamples");
    this.instrumentIssuingList = firebase.list("instrumentsIssuing");
  }

  form: FormGroup = new FormGroup({
    $key: new FormControl(null),
    Naziv: new FormControl(""),
    Tip: new FormControl(""),
    invetarskiBroj: new FormControl("", Validators.required),
    serijskiBroj: new FormControl("", Validators.required),
    Proizvodjac: new FormControl(""),
    GodinaProizvodnje: new FormControl("", Validators.required),
    NacinNabavke: new FormControl(""),
    Cena: new FormControl(""),
    Status: new FormControl("", Validators.required),
    Komentar: new FormControl(""),
    Prostorija: new FormControl(""),
    Polica: new FormControl(""),
    Skole: new FormControl(""),
  });
  formEdit: FormGroup = new FormGroup({
    $key: new FormControl(null),
    Naziv: new FormControl(""),
    Tip: new FormControl(""),
    invetarskiBroj: new FormControl(""),
    serijskiBroj: new FormControl(""),
    Proizvodjac: new FormControl(""),
    GodinaProizvodnje: new FormControl(""),
    NacinNabavke: new FormControl(""),
    Cena: new FormControl(""),
    Status: new FormControl(""),
    Komentar: new FormControl(""),
    Prostorija: new FormControl(""),
    Polica: new FormControl(""),
  });

  getInstruments() {
    this.instrumentList = this.firebase.list("instruments");
    return this.instrumentList.snapshotChanges();
  }
  getInstrumentTypes() {
    this.instrumentTypeList = this.firebase.list("instrumentTypes");
    return this.instrumentTypeList.snapshotChanges();
  }
  getInstrumentMakers() {
    this.instrumentMakerList = this.firebase.list("instrumentMakers");
    return this.instrumentMakerList.snapshotChanges();
  }

  getInstrumentsBySelectedSchool(schoolKey?) {
    if (schoolKey === "1") schoolKey = 1;

    return this.firebase
      .list("/instrumentSamples/" + schoolKey)
      .snapshotChanges();
  }

  getAllInstrumentSamples() {
    return this.db.list("/instrumentSamples/").snapshotChanges();
  }

  getMusicalSchoolByKey(key) {
    this.musicalSchool = this.firebase.object("Skole/" + key);
    return this.musicalSchool.valueChanges();
  }

  getInstrumentPurchaseType() {
    this.instrumentPurchaseTypeList = this.firebase.list(
      "instrumentPurchaseType"
    );
    return this.instrumentPurchaseTypeList.snapshotChanges();
  }

  getInstrumentStatuses() {
    this.instrumentStatusList = this.firebase.list("instrumentStatuses");
    return this.instrumentStatusList.snapshotChanges();
  }

  getInstrumentSamples() {
    this.instrumentSamplesList = this.firebase.list("instrumentSamples");
    return this.instrumentSamplesList.snapshotChanges();
  }

  getInstrumentObjectBySelectedKey($key: string) {
    this.instrumentObject = this.firebase.object("instrumentSamples/" + $key);
    return this.instrumentObject.valueChanges();
  }

  getInstrumentIssuingByUser(schoolKey) {
    if (schoolKey === "1") schoolKey = 1;

    this.instrumentIssuingList = this.firebase.list(
      "instrumentsIssuing/" + schoolKey
    );
    return this.instrumentIssuingList.snapshotChanges();
  }

  getAllInstrumentIssuing(schoolKey?) {
    this.instrumentIssuingList = this.firebase.list(
      "instrumentsIssuing/" + schoolKey
    );
    return this.instrumentIssuingList.snapshotChanges();
  }

  insertInstrument(instrumentInput) {
    this.instrumentList.push({
      Naziv: instrumentInput,
    });
  }

  insertInstrumentType(instrumentInputType) {
    this.instrumentTypeList.push({
      Naziv: instrumentInputType,
    });
  }

  insertInstrumentMaker(instrumentMakerInput) {
    this.instrumentMakerList.push({
      Naziv: instrumentMakerInput,
    });
  }

  insertInstrumentPurchaseType(instrumentPurchaseTypeInput) {
    this.instrumentPurchaseTypeList.push({
      Naziv: instrumentPurchaseTypeInput,
    });
  }

  insertInstrumentStatus(instrumentStatusInput) {
    this.instrumentStatusList.push({
      Naziv: instrumentStatusInput,
    });
  }

  insertInstrumentSample(instrument, imageUrls, inputOfAutoCompletes) {
    if (imageUrls.length == 0) imageUrls[0] = "";

    if (!instrument.Polica) instrument.Polica = "";
    if (!instrument.Prostorija) instrument.Prostorija = "";
    if (!instrument.Komentar) instrument.Komentar = "";

    this.authService
      .getObjectOfLoggedUser(this.authService.logedUserUID())
      .subscribe((item) => {
        this.instrumentSamplesList = this.firebase.list(
          "instrumentSamples/" + item.School.key
        );

        let skolaZaInput = this.authService.logedUser.School.Naziv;

        this.instrumentSamplesList.push({
          Naziv: inputOfAutoCompletes.Naziv,
          Tip: inputOfAutoCompletes.Tip,
          invetarskiBroj: instrument.invetarskiBroj,
          serijskiBroj: instrument.serijskiBroj,
          Proizvodjac: inputOfAutoCompletes.Proizvodjac,
          GodinaProizvodnje: instrument.GodinaProizvodnje,
          NacinNabavke: inputOfAutoCompletes.NacinNabavke,
          Cena: this.numberWithCommas(instrument.Cena),
          Status: instrument.Status,
          Komentar: instrument.Komentar,
          imageUrls: imageUrls,
          Prostorija: instrument.Prostorija,
          Polica: instrument.Polica,
          School: {
            Naziv: skolaZaInput,
            key: item.School.key,
          },
          SchoolKey: item.School.key,
        });
      });
  }

  insertInstrumentIssuing(User, Instrument) {
    if (Instrument.returns) {
      delete Instrument.returns;
    }
    delete Instrument.imageUrls;

    if (!User.brojLicneKarte) delete User.brojLicneKarte;

    this.instrumentIssuingList = this.firebase.list("instrumentsIssuing/");

    this.instrumentIssuingList.set(Instrument.key + this.makeid(10), {
      Instrument: Instrument,
      User: User,
    });
  }

  updateInstrumentPhotoToRealTimeDatabase(instrumentKey, imageUrls) {
    if (imageUrls.length == 0) imageUrls[0] = "";

    this.firebase.object("/instrumentSamples/" + instrumentKey).update({
      imageUrls: imageUrls,
    });
    this.sharedClass.progressValue = 0;
    this.sharedClass.imageFiles = [];
    this.sharedClass.downloadURLs = [];
    this.sharedClass.oneImagedownloadUrl = [];
    this.sharedClass.fileCounter = 0;
  }

  updateInstrument(instrumentKey, instrument, school) {
    this.firebase.object("/instrumentSamples/" + instrumentKey).update({
      Naziv: instrument.Naziv,
      Cena: this.numberWithCommas(instrument.Cena),
      GodinaProizvodnje: instrument.GodinaProizvodnje,
      Komentar: instrument.Komentar,
      NacinNabavke: instrument.NacinNabavke,
      Polica: instrument.Polica,
      Prostorija: instrument.Prostorija,
      Status: instrument.Status,
      Tip: instrument.Tip,
      Proizvodjac: instrument.Proizvodjac,
      invetarskiBroj: instrument.invetarskiBroj,
      serijskiBroj: instrument.serijskiBroj,
      School: school,
    });
  }
  updateInstrumentStatus(instrumentKey, status?) {
    this.firebase.object("/instrumentSamples/" + instrumentKey).update({
      Status: status,
    });

    if (status === "Za izdavanje") {
      this.instrumentSamplesList = this.firebase.list(
        "instrumentSamples/" + instrumentKey + "/returns"
      );
      this.instrumentSamplesList.push({
        InstrumentReturned:
          new Date().toDateString() + "/" + new Date().toLocaleTimeString(),
      });
    }
  }

  updateInstrumentStatusOnReport(reportId) {
    this.firebase
      .object("/instrumentsIssuing/" + reportId + "/Instrument")
      .update({
        Status: "Razdužen:" + this.sharedClass.dateFormatddmmyyyy(),
      });
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(".");
  }

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  deleteInstrument($key: string) {
    this.instrumentList.remove($key);
  }

  deleteInstrumentType($key: string) {
    this.instrumentTypeList.remove($key);
  }

  deleteInstrumentMaker($key: string) {
    this.instrumentMakerList.remove($key);
  }

  deleteInstrumentPurchaseType($key: string) {
    this.instrumentPurchaseTypeList.remove($key);
  }

  deleteInstrumentStatus($key: string) {
    this.instrumentStatusList.remove($key);
  }
}
