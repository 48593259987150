import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';


const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forRoot(routes), MatDatepickerModule,
    MatNativeDateModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
