import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Instrument } from "../instrument-input/instrument-input.component";
import { InstrumentsService } from "../instruments.service";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDatepickerInputEvent,
} from "@angular/material";
import { UserService } from "src/app/user/user.service";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { AuthService } from "src/app/auth/auth.service";
import { FormControl } from "@angular/forms";
import { SharedClass } from "src/app/shared/shared-functions";

@Component({
  selector: "app-instrument-issuing",
  templateUrl: "./instrument-issuing.component.html",
  styleUrls: ["./instrument-issuing.component.css"],
})
export class InstrumentIssuingComponent implements OnInit {
  userObject: User;

  filteredOptionsUser: Observable<string[]>;
  optionsUser: any = [];
  myControlUser = this.userService.form.controls.User;
  @ViewChild("ImePrezime", { static: false }) inputUser: any;

  instrument: any = [];
  users: User[] = [];
  user: firebase.User;
  showReverse: boolean = false;
  selectedUser: any;

  currentDate: any = this.sharedClass.dateFormatddmmyyyy();
  date = new FormControl(new Date());
  minDate = new Date();
  @ViewChild("cenaIznajmljivanja", { static: false }) inputCena: any;

  constructor(
    private instrumentService: InstrumentsService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userService: UserService,
    private authService: AuthService,
    public sharedClass: SharedClass
  ) {}

  ngOnInit() {
    this.authService.getUserState().subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.authService
          .getObjectOfLoggedUser(user.uid)
          .subscribe((item) => (this.userObject = item));
        this.filterUsers();
      }
    });

    this.instrumentService
      .getInstrumentObjectBySelectedKey(this.data.instrumentKey)
      .subscribe((item) => (this.instrument = item));

    this.filteredOptionsUser = this.myControlUser.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterUser(value))
    );

    this.userService.form.reset();
  }

  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsUser.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  takeUsers() {
    this.optionsUser = [];
    for (let i = 0; i < this.users.length; i++) {
      this.optionsUser[i] =
        i +
        1 +
        ". " +
        this.users[i].firstname +
        " " +
        this.users[i].lastname +
        " - " +
        this.users[i].role +
        " (" +
        this.users[i].email +
        ")";
    }
  }

  filterUsers() {
    this.userService.getAllUsers().subscribe((list) => {
      this.users = list
        .map((item) => {
          return {
            $key: item.key,
            ...item.payload.val(),
          };
        })
        .filter((item: any) => {
          if (this.userObject.role === "SuperAdmin") {
            return item;
          } else if (this.userObject.role === "AdminSkole") {
            return item.School.key === this.userObject.School.key;
          }
        });
      this.takeUsers();
    });
  }

  updateInstrumentStatus() {
    this.instrumentService.updateInstrumentStatus(
      this.data.instrumentKey,
      "Zauzet"
    );
    this.issueInstrument();
  }

  issueInstrument() {
    this.instrument.key = this.data.instrumentKey;
    this.instrument.IssuingTime = new Date().toLocaleTimeString();
    this.instrument.IssuingDate = this.currentDate;
    this.instrument.Cena = this.inputCena.nativeElement.value;
    this.instrument.Status = "Zauzet";

    this.instrument.UserWhoIssueTheInstrument = {
      Name: this.userObject.firstname + " " + this.userObject.lastname,
      key: this.user.uid,
    };

    let selectedUser = this.users.filter(
      (x) => x.email === this.getEmailOfSelectedUser()
    )[0];

    let user: {
      key: string;
      School: any;
      email: string;
      imePrezime: string;
      adresa: string;
      brLicneKarte: string;
    } = {
      key: selectedUser.$key,
      School: selectedUser.School,
      email: selectedUser.email,
      imePrezime: selectedUser.firstname + " " + selectedUser.lastname,
      adresa: selectedUser.adresa,
      brLicneKarte: selectedUser.brojLicneKarte,
    };

    this.instrumentService.insertInstrumentIssuing(user, this.instrument);

    this.inputUser.nativeElement.value = "";
    this.filteredOptionsUser[0] = "";
    this.userService.form.reset();
    this.closeDialog();
  }

  getEmailOfSelectedUser() {
    let selectedUser = this.inputUser.nativeElement.value;
    return selectedUser.substring(
      selectedUser.lastIndexOf("(") + 1,
      selectedUser.lastIndexOf(")")
    );
  }

  onOpenReverse() {
    this.selectedUser = this.users.filter(
      (x) => x.email === this.getEmailOfSelectedUser()
    )[0];

    this.showReverse = true;
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
