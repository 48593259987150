import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SharedClass } from 'src/app/shared/shared-functions';
import { InstrumentsService } from '../../instruments.service';

@Component({
  selector: 'app-issuing-detail',
  templateUrl: './issuing-detail.component.html',
  styleUrls: ['./issuing-detail.component.css']
})
export class IssuingDetailComponent implements OnInit {

  user: firebase.User;
  userObject: User;
  userIssues: any[] = [];
  issue: any = {};

  currentDate: any = +(new Date().getMonth() + 1) + '.' + new Date().getDate() + '.' + new Date().getFullYear() + '.';

  constructor(private auth: AuthService,
    private router: Router, private dialog: MatDialog,
    private sharedClass: SharedClass,
    private instrumentService: InstrumentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }


  ngOnInit() {
    this.auth.getUserState()
      .subscribe(user => {
        this.user = user;
        if (this.user) {
          this.auth.getObjectOfLoggedUser(user.uid).subscribe(item => {
            this.userObject = item;
            this.loadUserIssues(user.uid);

          });
          this.issue = this.data.issue;
          this.sharedClass.oneImagedownloadUrl = [];
        }
      })

  }

  updateInstrumentStatus() {

    if (confirm("Da li ste sigurni da želite da razdužite instrument?")) {
      this.instrumentService.updateInstrumentStatus(this.issue.Instrument.key, 'Za izdavanje');
      this.instrumentService.updateInstrumentStatusOnReport(this.data.issuingKey)
      this.closeDialog();
    }

  }

  loadUserIssues(uid) {
    this.instrumentService.getInstrumentIssuingByUser(uid).subscribe(
      list => {
        this.userIssues = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
