import { Instrument } from './../instrument.model';
import { InstrumentsService } from './../instruments.service';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatDialog, MatAutocompleteTrigger, MAT_DIALOG_DATA } from '@angular/material';
import { SharedClass } from 'src/app/shared/shared-functions';
import { AuthService } from 'src/app/auth/auth.service';


export class InstrumentMaker {
  Naziv: string;
}

export class InstrumentStatus {
  Naziv: string;
}
export class InstrumentType {
  Naziv: string;
}

export class InstrumentPurchaseType {
  Naziv: string;
}
@Component({
  selector: 'app-instrument-edit',
  templateUrl: './instrument-edit.component.html',
  styleUrls: ['./instrument-edit.component.css']
})
export class InstrumentEditComponent implements OnInit {

  filteredOptionsNaziv: Observable<string[]>;
  filteredOptionsTip: Observable<string[]>;
  filteredOptionsProizvodjac: Observable<string[]>;
  filteredOptionsNacinNabavke: Observable<string[]>;

  optionsNaziv: any = [];
  optionsTip: any = [];
  optionsProizvodjac: any = [];
  optionsNacinNabavke: any = [];
  instruments: Instrument[];
  instrumentTypes: InstrumentType[];
  instrumentMakers: InstrumentMaker[];
  instrumentPurchaseTypes: InstrumentPurchaseType[];
  instrumentStatuses: InstrumentStatus[];

  myControlNaziv = this.instrumentService.form.controls.Naziv;
  myControlTip = this.instrumentService.form.controls.Tip;
  myControlProizvodjac = this.instrumentService.form.controls.Proizvodjac;
  myControlNacinNabavke = this.instrumentService.form.controls.NacinNabavke;
  yearsOfProduction: number[] = []
  submitted: boolean;
  inputValidators: boolean[] = [true, true, true, true]

  @ViewChild('Naziv', { static: true }) inputNaziv: any;
  @ViewChild('Tip', { static: true }) inputTip: any;
  @ViewChild('Proizvodjac', { static: true }) inputProizvodjac: any;
  @ViewChild('NacinNabavke', { static: true }) inputNacinNabavke: any;
  @ViewChild('inventarskiBroj', { static: true }) inputInventarskiBroj: any;
  @ViewChild('serijskiBroj', { static: true }) inputSerijskiBroj: any;
  @ViewChild('Cena', { static: true }) inputCena: any;
  @ViewChild('Komentar', { static: true }) inputKomentar: any;
  @ViewChild('Polica', { static: true }) inputPolica: any;
  @ViewChild('Prostorija', { static: true }) inputProstorija: any;

  instrumentElement: Instrument;
  selectedYear;
  selectedStatus;// = 'Aktivan';
  userObject: User = {}
  files: any;
  rowsTextArea: number = 0;


  constructor(public instrumentService: InstrumentsService,
    private dialog: MatDialog,
    public sharedClass: SharedClass,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    let j = 0;
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      this.yearsOfProduction[j++] = i;
    }
  }


  ngOnInit() {
    this.loadInstruments();
    this.loadInstrumentTypes();
    this.loadInstrumentMakers();
    this.loadInstrumentPurchaseTypes();
    this.loadInstrumentStatuses();
    this.loadSelectedInstrument();

    this.filteredOptionsNaziv = this.myControlNaziv.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNaziv(value))
      );
    this.filteredOptionsTip = this.myControlTip.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterTip(value))
      );
    this.filteredOptionsProizvodjac = this.myControlProizvodjac.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterProizvodjac(value))
      );
    this.filteredOptionsNacinNabavke = this.myControlNacinNabavke.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterNacinNabavke(value))
      );
  }
  private _filterNaziv(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsNaziv.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterTip(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsTip.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterProizvodjac(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsProizvodjac.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterNacinNabavke(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsNacinNabavke.filter(option => option.toLowerCase().includes(filterValue));
  }

  loadSelectedInstrument() {
    this.instrumentService.getInstrumentObjectBySelectedKey(this.data.instrumentKey).subscribe(
      item => {
        this.instrumentElement = item;
        this.sharedClass.oneImagedownloadUrl = item.imageUrls,
          this.myControlNaziv.setValue(item.Naziv);
        this.myControlNacinNabavke.setValue(item.NacinNabavke);
        this.myControlProizvodjac.setValue(item.Proizvodjac);
        this.myControlTip.setValue(item.Tip);
        this.selectedYear = item.GodinaProizvodnje,
          this.selectedStatus = item.Status;
        this.rowsTextArea = item.Komentar.length / 45;
      }
    )
  }

  onSubmit() {

    this.authService.getObjectOfLoggedUser(this.authService.logedUserUID()).subscribe(item => {
      this.userObject = item;

      let instrumentEditValues: {
        Naziv: string,
        Tip: string,
        Proizvodjac: string,
        NacinNabavke: string,
        invetarskiBroj: string,
        serijskiBroj: string,
        Cena: string,
        Komentar: string,
        Polica: string,
        Prostorija: string,
        Status: string,
        GodinaProizvodnje: string,

      } = {
        Naziv: this.inputNaziv.nativeElement.value,
        Tip: this.inputTip.nativeElement.value,
        Proizvodjac: this.inputProizvodjac.nativeElement.value,
        NacinNabavke: this.inputNacinNabavke.nativeElement.value,
        invetarskiBroj: this.inputInventarskiBroj.nativeElement.value,
        serijskiBroj: this.inputSerijskiBroj.nativeElement.value,
        Cena: this.inputCena.nativeElement.value,
        Komentar: this.inputKomentar.nativeElement.value,
        Polica: this.inputPolica.nativeElement.value,
        Prostorija: this.inputProstorija.nativeElement.value,
        Status: this.selectedStatus,
        GodinaProizvodnje: this.selectedYear


      }

      if (!this.checkIfNameExistsAutoComplete(instrumentEditValues.Naziv))
        this.instrumentService.insertInstrument(instrumentEditValues.Naziv);

      if (!this.checkIfInstrumentTypeExistsAutoComplete(instrumentEditValues.Tip))
        this.instrumentService.insertInstrumentType(instrumentEditValues.Tip);

      if (!this.checkIfMakerNameExistsAutoComplete(instrumentEditValues.Proizvodjac))
        this.instrumentService.insertInstrumentMaker(instrumentEditValues.Proizvodjac);

      if (!this.checkIfPurchaseTypeExistsAutoComplete(instrumentEditValues.NacinNabavke))
        this.instrumentService.insertInstrumentPurchaseType(instrumentEditValues.NacinNabavke);

      this.instrumentService.updateInstrument(this.data.instrumentKey, instrumentEditValues, this.userObject.School)

    })

    this.instrumentService.updateInstrumentPhotoToRealTimeDatabase(this.data.instrumentKey, this.sharedClass.oneImagedownloadUrl)

    //reseting values in sharedClass for next upload
    this.sharedClass.progressValue = 0;
    this.sharedClass.imageFiles = [];
    this.sharedClass.downloadURLs = []
    this.sharedClass.oneImagedownloadUrl = []
    this.sharedClass.fileCounter = 0;

  }

  checkIfNameExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instruments.length; i++) {
      if (naziv == this.instruments[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfInstrumentTypeExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentTypes.length; i++) {
      if (naziv == this.instrumentTypes[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfMakerNameExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentMakers.length; i++) {
      if (naziv == this.instrumentMakers[i].Naziv)
        return true;
    }
    return false;
  }

  checkIfPurchaseTypeExistsAutoComplete(naziv: string) {
    for (let i = 0; i < this.instrumentPurchaseTypes.length; i++) {
      if (naziv == this.instrumentPurchaseTypes[i].Naziv)
        return true;
    }
    return false;
  }

  loadInstruments() {
    this.instrumentService.getInstruments().subscribe(
      list => {
        this.instruments = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }
  loadInstrumentTypes() {
    this.instrumentService.getInstrumentTypes().subscribe(
      list => {
        this.instrumentTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentMakers() {
    this.instrumentService.getInstrumentMakers().subscribe(
      list => {
        this.instrumentMakers = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentPurchaseTypes() {
    this.instrumentService.getInstrumentPurchaseType().subscribe(
      list => {
        this.instrumentPurchaseTypes = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  loadInstrumentStatuses() {
    this.instrumentService.getInstrumentStatuses().subscribe(
      list => {
        this.instrumentStatuses = list.map(item => {
          return {
            $key: item.key,
            ...item.payload.val()
          }
        })
      }
    );
  }

  takeInstruments() {
    this.optionsNaziv = []
    for (let i = 0; i < this.instruments.length; i++) {
      this.optionsNaziv[i] = this.instruments[i].Naziv;
    }
  }
  takeInstrumentsType() {
    this.optionsTip = []
    for (let i = 0; i < this.instrumentTypes.length; i++) {
      this.optionsTip[i] = this.instrumentTypes[i].Naziv;
    }
  }
  takeInstrumentsMaker() {
    this.optionsProizvodjac = []
    for (let i = 0; i < this.instrumentMakers.length; i++) {
      this.optionsProizvodjac[i] = this.instrumentMakers[i].Naziv;
    }
  }

  takeInstrumentPurchaseType() {
    this.optionsNacinNabavke = []
    for (let i = 0; i < this.instrumentPurchaseTypes.length; i++) {
      this.optionsNacinNabavke[i] = this.instrumentPurchaseTypes[i].Naziv;
    }
  }

  checkNaziv(event) {
    if (event.target.value != '') {
      this.inputValidators[0] = true;
    }
    else {
      this.inputValidators[0] = false;
    }
  }

  checkTip(event) {
    if (event.target.value != '') {
      this.inputValidators[1] = true;
    }
    else {
      this.inputValidators[1] = false;
    }
  }
  checkProizvodjac(event) {
    if (event.target.value != '') {
      this.inputValidators[2] = true;
    }
    else {
      this.inputValidators[2] = false;
    }
  }
  checkNacinNabavke(event) {
    if (event.target.value != '') {
      this.inputValidators[3] = true;
    }
    else {
      this.inputValidators[3] = false;
    }
  }

  uploadImages(event) {
    this.sharedClass.uploadedBoolean = false;
    this.sharedClass.uploadImages(event, 'instruments');
  }

  uploadImage(event) {
    this.sharedClass.uploadImage(event, 'instruments');
  }

  onDeletePhoto(url) {
    this.sharedClass.deletePhotoFromFirebaseStorage(url)
  }

  disableButton(event) {
    event.target.disabled = true;
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
