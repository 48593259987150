<div flex style="margin: 5% 10%">
    <div style="text-align: center;">
        <mat-accordion mat-list-item>
            <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header style="background-color:#34b1eb;">
                    <mat-panel-title style="color:white">
                        <p>Izmena instrumenta</p>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <form #instrumentForm="ngForm" style="margin-top: 2%;" [formGroup]="this.instrumentService.formEdit">
                    <input type="hidden" formControlName="$key">
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input ng-model="Naziv" (blur)="checkNaziv($event)" (keydown.Tab)="checkNaziv($event)" (keyup)="checkNaziv($event)" tab-directive (keyup)="takeInstruments();" type="text" formControlName="Naziv" #Naziv placeholder="Naziv" aria-label="Number" matInput
                                    [formControl]="myControlNaziv" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsNaziv | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="inputValidators[0]">done</mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input #inventarskiBroj [value]="instrumentElement?.invetarskiBroj" style="width:400px !important" formControlName="invetarskiBroj" type="text" matInput placeholder="Invetarski broj" value="">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="instrumentService.form.controls.invetarskiBroj.valid">done</mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input #serijskiBroj [value]="instrumentElement?.serijskiBroj" formControlName="serijskiBroj" type="text" matInput placeholder="Serijski broj" value="">
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="instrumentService.form.controls.serijskiBroj.valid">done</mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input (blur)="checkTip($event)" (keydown.Tab)="checkTip($event)" (keyup)="checkTip($event)" tab-directive (keyup)="takeInstrumentsType()" type="text" formControlName="Tip" #Tip placeholder="Tip instrumenta" aria-label="Number" matInput [formControl]="myControlTip"
                                    [matAutocomplete]="autoTip">
                                <mat-autocomplete #autoTip="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsTip | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="inputValidators[1]">done</mat-icon>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input (keyup)="takeInstrumentsMaker()" type="text" formControlName="Proizvodjac" #Proizvodjac (blur)="checkProizvodjac($event)" (keydown.Tab)="checkProizvodjac($event)" (keyup)="checkProizvodjac($event)" tab-directive placeholder="Proizvođač" aria-label="Number"
                                    matInput [formControl]="myControlProizvodjac" [matAutocomplete]="autoProizvodjac">
                                <mat-autocomplete #autoProizvodjac="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsProizvodjac | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="inputValidators[2]">done</mat-icon>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field md-no-asterisk style="width:100% !important">
                                <mat-label>Godina</mat-label>
                                <select formControlName="GodinaProizvodnje" [(ngModel)]='selectedYear' matNativeControl>
                  <option value="-">-</option>
                  <!-- <option value="this.instrumentElement.GodinaProizvodnje">{{this.instrumentElement.GodinaProizvodnje}}</option> -->
                  <option *ngFor="let year of yearsOfProduction.reverse()" value="{{year}}">{{year}}</option>
                </select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="instrumentService.form.controls.GodinaProizvodnje.valid">done
                            </mat-icon>
                        </div>
                    </div>

                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input (keyup)="takeInstrumentPurchaseType()" type="text" formControlName="NacinNabavke" #NacinNabavke tab-directive (blur)="checkNacinNabavke($event)" (keydown.Tab)="checkNacinNabavke($event)" (keyup)="checkNacinNabavke($event)" placeholder="Način nabavke"
                                    aria-label="Number" matInput [formControl]="myControlNacinNabavke" [matAutocomplete]="autoPurchaseType">
                                <mat-autocomplete #autoPurchaseType="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsNacinNabavke | async" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-icon style="color:green" *ngIf="inputValidators[3]">done
                            </mat-icon>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input #Cena [value]="instrumentElement?.Cena" formControlName="Cena" type="number" matInput placeholder="Procenjena vrednost (RSD)" value="">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important">
                                <mat-label>Status</mat-label>
                                <mat-select [(ngModel)]="selectedStatus" formControlName="Status">
                                    <mat-option [value]="selectedStatus"> {{selectedStatus}}</mat-option>
                                    <ng-container *ngFor="let instrumentStatusItem of instrumentStatuses">
                                        <mat-option *ngIf="instrumentStatusItem.Naziv != selectedStatus" [value]="instrumentStatusItem?.Naziv">
                                            {{instrumentStatusItem.Naziv}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input #Prostorija style="width:400px !important" formControlName="Prostorija" type="text" matInput placeholder="Prostorija" [value]="instrumentElement?.Prostorija">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <input #Polica style="width:400px !important" formControlName="Polica" type="text" matInput placeholder="Polica" [value]="instrumentElement?.Polica">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row">
                        <div style="width:100% !important">
                            <mat-form-field style="width:100% !important" class="example-full-width">
                                <textarea rows="{{rowsTextArea}}" cols="50" #Komentar [value]="instrumentElement?.Komentar" formControlName="Komentar" matInput placeholder="Komentar"></textarea>
                            </mat-form-field>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%" style="justify-content: center;">
                        <div style="display: flex; flex-flow: row wrap; ">
                            <div *ngFor="let imageUrl of sharedClass.oneImagedownloadUrl" style="padding: 5px;">
                                <mat-card *ngIf="imageUrl!= ''">
                                    <div style="display: flex; flex-flow: column wrap;">
                                        <div>
                                            <img mat-card-image style="height: 200px; width: auto;  max-width:100%; max-height: 100%;
                                    overflow: hidden;" src="{{imageUrl}}">
                                        </div>
                                        <div>
                                            <button mat-stroked-button (click)="onDeletePhoto(imageUrl)">
                        <mat-icon>delete</mat-icon>
                      </button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                        <div style="flex:1" *ngIf="this.sharedClass.oneImagedownloadUrl[0] == '' || this.sharedClass.oneImagedownloadUrl.length == 0">
                            <ngx-dropzone (change)="uploadImage($event)">
                                <ngx-dropzone-label>Foto 1.</ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                        <div style="flex:1" *ngIf="this.sharedClass.oneImagedownloadUrl.length <= 1">
                            <ngx-dropzone (change)="uploadImage($event)">
                                <ngx-dropzone-label>Foto 2.</ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                        <div style="flex:1" *ngIf="this.sharedClass.oneImagedownloadUrl.length <= 2">
                            <ngx-dropzone (change)="uploadImage($event)">
                                <ngx-dropzone-label>Foto 3.</ngx-dropzone-label>
                                <ngx-dropzone-preview *ngFor="let f of files" [removable]="true">
                                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div><br>
                    <button mat-stroked-button color="primary" (click)="onSubmit(); closeDialog()" [disabled]="!inputValidators[0] ||
            !inputValidators[1] || !inputValidators[2] || !inputValidators[3]">
            <mat-icon>edit</mat-icon>Izmeni
          </button>
                </form>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>