import { NgModule } from "@angular/core";
import { environment } from "./../environments/environment";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { InstrumentsComponent } from "./instruments/instruments.component";
import { InstrumentListComponent } from "./instruments/instrument-list/instrument-list.component";
import { InstrumentInputComponent } from "./instruments/instrument-input/instrument-input.component";
import { AngularFireStorage } from "@angular/fire/storage";
import { NgxDropzoneModule } from "ngx-dropzone";
import { TabDirective } from "./instruments/instrument-input/tab-directive";
import { InstrumentItemComponent } from "./instruments/instrument-list/instrument-item/instrument-item.component";
import { InstrumentEditComponent } from "./instruments/instrument-edit/instrument-edit.component";
import { InstrumentTableComponent } from "./instruments/instrument-table/instrument-table.component";
import { PrintConfigurationComponent } from "./instruments/print-layout/print-configuration/print-configuration.component";
import { PrintLayoutComponent } from "./instruments/print-layout/print-layout.component";
import { NgxPrintModule } from "ngx-print";
import { InstrumentDetailComponent } from "./instruments/instrument-detail/instrument-detail.component";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { LoginComponent } from "./auth/login/login.component";
import { RegistrationComponent } from "./auth/registration/registration.component";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { UserProfileComponent } from "./auth/user-profile/user-profile.component";
import { UserListComponent } from "./user/user-list/user-list.component";
import { UserDialogComponent } from "./user/user-dialog/user-dialog.component";
import { InstrumentIssuingComponent } from "./instruments/instrument-issuing/instrument-issuing.component";
import { IssuingDetailComponent } from "./instruments/instrument-issuing/issuing-detail/issuing-detail.component";
import { AuthGuard } from "./auth/auth-guard.service";
import { InstrumentReportsComponent } from "./instruments/instrument-reports/instrument-reports.component";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { PrintreportConfigurationComponent } from "./instruments/instrument-reports/printreport-configuration/printreport-configuration.component";
import { PrintreportLayoutComponent } from "./instruments/instrument-reports/printreport-layout/printreport-layout.component";
import { CommentViewComponent } from "./instruments/instrument-list/instrument-item/comment-view/comment-view.component";
// import { ReservationComponent } from './reservation/reservation.component';
// import { MessageComponent } from './reservation/message/message.component';
// import { PersonalMessagesComponent } from './reservation/personal-messages/personal-messages.component';

const appRoutes: Routes = [
  {
    path: "unosInstrumenta",
    component: InstrumentInputComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "pregledInstrumenata",
    component: InstrumentListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tabelaInstrumenti",
    component: InstrumentTableComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "instrumentiStampa",
    component: PrintLayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "korisnici",
    component: UserListComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "izdavanjeInstrumenata",
    component: InstrumentIssuingComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "izvestaji",
    component: InstrumentReportsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: "izvestajiStampa",
    component: PrintreportLayoutComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  // { path: 'rezervacije', component: ReservationComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  // { path: 'pošta', component: PersonalMessagesComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "register",
    component: RegistrationComponent,
  },
  {
    path: "userProfile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
];

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    InstrumentsComponent,
    InstrumentListComponent,
    InstrumentInputComponent,
    TabDirective,
    InstrumentItemComponent,
    InstrumentEditComponent,
    InstrumentTableComponent,
    PrintLayoutComponent,
    PrintConfigurationComponent,
    InstrumentDetailComponent,
    LoginComponent,
    RegistrationComponent,
    UserProfileComponent,
    UserListComponent,
    UserDialogComponent,
    InstrumentIssuingComponent,
    IssuingDetailComponent,
    InstrumentReportsComponent,
    PrintreportConfigurationComponent,
    PrintreportLayoutComponent,
    CommentViewComponent,
    // ReservationComponent,
    // MessageComponent,
    // PersonalMessagesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDropzoneModule,
    NgxPrintModule,
    ScrollToModule.forRoot(),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [AngularFireStorage],
  bootstrap: [AppComponent],
  entryComponents: [
    InstrumentEditComponent,
    PrintConfigurationComponent,
    InstrumentDetailComponent,
    LoginComponent,
    RegistrationComponent,
    UserDialogComponent,
    InstrumentIssuingComponent,
    IssuingDetailComponent,
    PrintreportConfigurationComponent,
    CommentViewComponent,
    // MessageComponent,
  ],
})
export class AppModule {}
